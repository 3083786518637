import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Button,
} from "@chakra-ui/react";

const MentorSignupPoster = () => {
  const backgroundGradient =
    "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))";
  const backgroundImage = `${backgroundGradient}, url('your-image-url-here.jpg')`;
  const titleGradient = "linear-gradient(90deg, #00c6ff, #0072ff)";
  const buttonGradient = "linear-gradient(90deg, #ff7e5f, #feb47b)";

  return (
    <Flex
      height="100vh"
      backgroundImage={backgroundImage}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      alignItems="center"
      justifyContent="center"
      color="white"
      fontFamily="'Helvetica Neue', sans-serif"
      bg="black"
    >
      <Box
        textAlign="center"
        padding="20px"
        maxWidth="600px"
        background="rgba(0, 0, 0, 0.6)"
        borderRadius="15px"
        boxShadow="0 4px 20px rgba(0, 0, 0, 0.5)"
      >
        <Heading
          fontSize="36px"
          fontWeight="bold"
          marginBottom="20px"
          bgGradient={titleGradient}
          bgClip="text"
        >
          Become a Mentor at menteementors.com
        </Heading>
        <Text fontSize="22px" marginBottom="30px" lineHeight="1.5">
          Sign up now and unlock exclusive perks:
        </Text>
        <UnorderedList
          listStyleType="none"
          padding="0"
          fontSize="20px"
          marginBottom="30px"
        >
          <ListItem margin="10px 0">
            🎉 Receive ₹100 (1st year) or ₹50 (2nd year) upon signup.
          </ListItem>
          <ListItem margin="10px 0">
            💬 Offer 1-on-1 mentorship calls based on your availability.
          </ListItem>
          <ListItem margin="10px 0">
            💸 Earn ₹250 - ₹500 per 1-hour session.
          </ListItem>
          <ListItem margin="10px 0">
            💰 Potential to earn up to ₹30,000 per month.
          </ListItem>
          <ListItem margin="10px 0">
            🗣️ Enhance your communication skills.
          </ListItem>
          <ListItem margin="10px 0">
            🚀 Top mentors get exclusive internship opportunities.
          </ListItem>
        </UnorderedList>
        <Button
          as="a"
          href="https://menteementors.com/mentor-registration"
          background={buttonGradient}
          color="white"
          padding="15px 40px"
          fontSize="18px"
          borderRadius="50px"
          textDecoration="none"
          _hover={{ transform: "scale(1.05)" }}
          transition="transform 0.3s ease"
        >
          Sign Up Now!
        </Button>
      </Box>
    </Flex>
  );
};

export default MentorSignupPoster;
