import { Box, Img } from "@chakra-ui/react";
import React from "react";
import MentorshipFeature from "../utils/MentorshipFeature";

const mentorshipFeatures = [
  {
    heading: "Personal Mentorship",
    content:
      "Receive personalized guidance throughout your NEET or IIT JEE preparation from experienced mentors.",
  },
  {
    heading: "Live Sessions",
    content:
      "Setup a live session with mentor for doubt clearing and concept clarity .",
  },
  {
    heading: "Study Planner",
    content:
      "Get assistance in creating a tailored study plan to ensure effective preparation for NEET and IIT JEE.",
  },
  {
    heading: "Doubt Sessions",
    content:
      "Join dedicated doubt sessions to resolve all your queries with the help of expert mentors.",
  },
  {
    heading: "Practice Strategies",
    content:
      "Access top preparation and revision strategies from NEET and IIT JEE mentors to excel in your exams.",
  },
  {
    heading: "Study Materials & Notes",
    content:
      "Gain access to handwritten notes and study materials from top performers to enhance your preparation.",
  },
  {
    heading: "Previous Years' Papers Analysis",
    content:
      "Get expert analysis of previous years' papers and learn effective techniques for attempting exams.",
  },
];

function MentorshipIntro() {
  return (
    <>
      <Box
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent="space-between"
      >
        <Box
          fontSize={{ base: "2xl", lg: "5xl" }}
          fontWeight="bold"
          marginRight="5%"
          marginLeft="5%"
        >
          <Box>
            <Box mb={4}>
              <h1>Mentorship Plans</h1>
            </Box>
            <Box
              fontSize={{ base: "xl", lg: "2xl" }}
              fontWeight="medium"
              mb={8}
            >
              <h1>Select the plan that matches your study goals!</h1>
            </Box>
          </Box>
        </Box>
        <Box>
          <Img
            height="80%"
            borderRadius={"20px"}
            src="https://images.pexels.com/photos/3867001/pexels-photo-3867001.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="buy-pic"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        margin={{ base: "20% 0", lg: "10% 0" }}
        border="0px"
        shadow="0px 0px 20px 0px rgba(0, 0, 0, 0.5)"
        padding="40px"
        borderRadius="20px"
        alignItems="center"
        justifyContent="space-around"
      >
        <Box marginRight={{ base: "0px", lg: "50px" }}>
          <Img
            height="100%"
            width="200%"
            borderRadius={"30px"}
            src="https://images.pexels.com/photos/935743/pexels-photo-935743.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="about-pic"
          />
        </Box>
        <Box
          maxW={{ base: "100%", lg: "40%" }}
          mt={{ base: "40px", lg: "0px" }}
        >
          <Box
            fontSize={{ base: "2xl", lg: "4xl" }}
            fontWeight="extrabold"
            mb={{ base: "20px", lg: "0px" }}
          >
            <h1>About Mentorship</h1>
          </Box>
          <Box
            fontSize={{ base: "md", lg: "xl" }}
            mt={{ base: "0%", lg: "5%" }}
          >
            <h1>
              At MenteeMentors, we connect you with experienced NEET and IIT JEE
              mentors for personalized guidance. Our mentors support you
              throughout your preparation, offering help whenever you need it.
            </h1>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        margin="20% 0"
        alignItems="center"
      >
        <Box maxW={{ base: "100%", lg: "60%" }}>
          <Box
            fontSize={{ base: "2xl", lg: "5xl" }}
            fontWeight="extrabold"
            lineHeight="shorter"
          >
            <h1>Succeed in NEET or IIT-JEE with Expert Mentor Guidance</h1>
          </Box>
          <Box
            fontSize={{ base: "md", lg: "xl" }}
            fontWeight="normal"
            marginTop={{ base: "10%", lg: "5%" }}
          >
            <h1>
              Get expert advice from recent toppers who understand your
              challenges. Our one-on-one sessions keep you ahead in your
              preparation and help you achieve your goals.
            </h1>
          </Box>
        </Box>
        <Box
          marginLeft={{ base: "0%", lg: "3%" }}
          mt={{ base: "30px", lg: "0px" }}
        >
          <Img
            height={{ base: "50%", lg: "100%" }}
            width={{ base: "100%", lg: "200%" }}
            borderRadius={"30px"}
            src="https://images.pexels.com/photos/1005803/pexels-photo-1005803.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="crack-exam-pic"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        flexFlow={{ base: "column-reverse", lg: "row" }}
        margin="20% 0"
        alignItems="center"
      >
        <Box
          marginRight={{ base: "0%", lg: "5%" }}
          mt={{ base: "30px", lg: "0px" }}
        >
          <Img
            src="https://images.pexels.com/photos/3776776/pexels-photo-3776776.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="benefits-pic"
            height={{ base: "100%", lg: "100%" }}
            width={{ base: "100%", lg: "100%" }}
            borderRadius={"30px"}
          />
        </Box>
        <Box
          maxW={{ base: "100%", lg: "55%" }}
          marginTop={{ base: "30px", lg: "0px" }}
        >
          <Box
            fontSize={{ base: "2xl", lg: "5xl" }}
            fontWeight="extrabold"
            lineHeight="shorter"
          >
            <h1>Benefits of MenteeMentors's Mentorship Program</h1>
          </Box>
          <Box
            fontSize={{ base: "md", lg: "xl" }}
            fontWeight="normal"
            marginTop={{ base: "10%", lg: "5%" }}
          >
            <h1>
              Proper mentorship is crucial for NEET and IIT JEE success.
              MenteeMentors offers access to highly qualified mentors who guide
              you through every step of your preparation. From effective exam
              strategies to daily sessions and valuable study materials, we
              provide everything you need to excel.
            </h1>
          </Box>
        </Box>
      </Box>
      <Box textAlign="center" marginBottom="10%">
        <Box fontSize={{ base: "3xl", lg: "5xl" }} fontWeight="extrabold">
          <h1>Mentorship Features</h1>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          margin="5% 0"
        >
          {mentorshipFeatures.map((feature, index) => (
            <MentorshipFeature feature={feature} key={index} />
          ))}
        </Box>
      </Box>
    </>
  );
}

export default MentorshipIntro;
