import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Link,
  Button,
  Stack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const BlogList = () => {
  const [openNavbar, setOpenNavbar] = useState(false);

  const blogs = [
    {
      title:
        "Building a Strong Class 11 Foundation for Guaranteed Future Success",
      path: "/blogs?id=Building-a-Strong-Class-11-Foundation-for-neet-exam",
    },
    {
      title:
        "Struggling with Physics? Here is What You Can Do to Succeed with Menteementors",
      path: "/blogs?id=Struggling-with-Physics?-How-MenteeMentors-Can-Help-You-Succeed",
    },
  ];

  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"5%"}
        paddingBottom={{ base: "0%", lg: "5%" }}
        paddingTop={{ base: "40%", lg: "10%" }}
        marginBottom={{ base: "0%", lg: "0%" }}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <VStack spacing="8" align="start">
          <Heading as="h1" size="2xl" color="teal.600">
            Our Blog
          </Heading>
          <Text fontSize="lg" color="gray.700">
            Welcome to our blog section! Here, you can find a wealth of
            information, tips, and insights to help you succeed in your academic
            and professional journey. Explore our latest posts and stay informed
            about the topics that matter most to you.
          </Text>
          <Stack spacing="4">
            {blogs.map((blog, index) => (
              <Box
                key={index}
                borderWidth="1px"
                borderRadius="lg"
                backgroundColor="white"
                boxShadow="md"
                padding="6"
              >
                <Heading as="h2" size="lg" color="teal.500">
                  <Link as={RouterLink} to={blog.path}>
                    {blog.title}
                  </Link>
                </Heading>
                <Text fontSize="md" color="gray.600" marginTop="2">
                  Discover insightful articles and updates related to our blog.
                  Click on the title to read more.
                </Text>
                <Button
                  marginTop="4"
                  colorScheme="teal"
                  variant="solid"
                  as={RouterLink}
                  to={blog.path}
                >
                  Read More
                </Button>
              </Box>
            ))}
          </Stack>
        </VStack>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default BlogList;
