import { Box, Button } from "@chakra-ui/react";
import React from "react";
import Step from "../utils/Step";

function Steps(props) {
  const steps = [
    {
      stepNumber: "STEP 1",
      intro: "Choose a mentor that matches your preferences.",
      actionButtonText: "Shortlist Mentor ->",
    },
    {
      stepNumber: "STEP 2",
      intro:
        "Try out a free trial session to see how the mentor can assist you.",
      actionButtonText: "Book Free Trial ->",
    },
    {
      stepNumber: "STEP 3",
      intro:
        "Begin preparing by working closely with your mentor in long-term one-on-one sessions.",
      actionButtonText: "Start Preparing ->",
    },
  ];
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      paddingLeft={"8%"}
      paddingRight={"8%"}
      paddingTop={{ base: "50px", lg: "0px" }}
    >
      <Box>
        <Button
          colorScheme="teal"
          variant="outline"
          textColor={"#EC490B"}
          border={"1px solid #EC490B"}
          borderRadius={"full"}
          fontSize={{ base: "3xl", lg: "md" }}
          padding={{ base: "30px 50px", lg: "20px 40px" }}
        >
          How To Begin
        </Button>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        marginTop={{ base: "5%", lg: "1%" }}
        marginBottom={{ base: "5%", lg: "1%" }}
      >
        <Box fontSize={{ base: "3xl", lg: "5xl" }} fontWeight={"extrabold"}>
          <h1>Get started in</h1>
          <h1>Three Easy Steps</h1>
        </Box>
        <Box></Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {steps.map((step, indx) => {
          return <Step {...step} key={indx}></Step>;
        })}
      </Box>
    </Box>
  );
}

export default Steps;
