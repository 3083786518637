import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Button,
  VStack,
} from "@chakra-ui/react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const RefundPolicy = () => {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"5%"}
        paddingBottom={{ base: "0%", lg: "5%" }}
        paddingTop={{ base: "40%", lg: "10%" }}
        marginBottom={{ base: "0%", lg: "0%" }}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Heading as="h1" size="lg" mb={4}>
          Privacy Policy
        </Heading>
        <VStack spacing="6" align="start">
          <Heading as="h1" size="2xl" color="teal.600">
            Refund Policy
          </Heading>
          <Text fontSize="lg" color="gray.700">
            At MenteeMentors, we are committed to providing the best mentoring
            experience. However, we understand that circumstances can change.
            Please review our refund policy below:
          </Text>
          <List spacing="4" fontSize="lg" color="gray.600">
            <ListItem>
              <strong>1. Cancellation within 24 hours:</strong> If you cancel
              your booking within 24 hours of making the payment, you are
              eligible for a full refund. Please contact our support team to
              process your refund.
            </ListItem>
            <ListItem>
              <strong>2. Cancellation 24-48 hours before the session:</strong>{" "}
              Cancellations made between 24 to 48 hours before the scheduled
              session are eligible for a 50% refund.
            </ListItem>
            <ListItem>
              <strong>
                3. Cancellation less than 24 hours before the session:
              </strong>{" "}
              No refund will be provided for cancellations made less than 24
              hours before the scheduled session.
            </ListItem>
            <ListItem>
              <strong>4. Rescheduling Policy:</strong> You may reschedule your
              session up to 24 hours before the scheduled time without any
              additional charge. Rescheduling requests made less than 24 hours
              before the session may incur a fee.
            </ListItem>
            <ListItem>
              <strong>5. No Show:</strong> If you fail to attend the session
              without prior notice, no refund will be provided.
            </ListItem>
          </List>
          <Text fontSize="lg" color="gray.700">
            For any questions or to request a refund, please contact our support
            team at{" "}
            <a
              href="mailto:support@menteementors.com"
              style={{ color: "teal.500" }}
            >
              support@menteementors.com
            </a>
            .
          </Text>
          <Button
            as="a"
            href="/"
            colorScheme="teal"
            size="lg"
            borderRadius="md"
          >
            Back to Home
          </Button>
        </VStack>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default RefundPolicy;
