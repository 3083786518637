import { Box, IconButton, Tooltip, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ProfileCard from "../utils/ProfileCard";
import ChoosePlan from "../body/ChoosePlan";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { hitBeApi } from "../../apis/api";
import { useDispatch } from "react-redux";

function Searches({ userMenteeProfile, mentees, mentors }) {
  const [shouldShowPlans, setShouldShowPlan] = useState(false);
  const [mentorSelectedId, setMentorSelectedId] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page

  // Fetch mentor data based on current page
  const fetchMentors = async (page) => {
    try {
      const response = await hitBeApi(`mentor?page=${page}`, "GET");
      if (response?.data?.length > 0) {
        const mentorProfiles = await Promise.all(
          response?.data?.map(async (mentor) => {
            const userRes = await hitBeApi(`user/${mentor.userId}`, "GET");
            mentor.profile = userRes?.data;
            return mentor;
          })
        );
        dispatch({ type: "Set_Mentors", value: mentorProfiles });
      } else {
        toast({
          title: "Warning",
          description: "No more mentors available",
          status: "warning", // Change "warn" to "warning"
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fetch mentors when the component mounts or when currentPage changes
  useEffect(() => {
    fetchMentors(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Handle next page
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Handle previous page (prevent going to page 0 or negative)
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleMenteeInterest = (mentorId) => {
    setShouldShowPlan(true);
    setMentorSelectedId(mentorId);
  };

  return (
    <Box position="relative">
      {shouldShowPlans && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={99}
        >
          <Box
            width={{ base: "100%", lg: "60%" }}
            backgroundColor="white"
            p={{ base: 2, lg: 6 }}
            borderRadius="md"
            boxShadow="xl"
            overflowY={"scroll"}
          >
            <ChoosePlan
              isUserLoggedIn={true}
              setShouldShowPlan={setShouldShowPlan}
              mentorSelectedId={mentorSelectedId}
            />
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        paddingTop="10px"
      >
        {userMenteeProfile
          ? mentors?.map((mentor, index) => {
              return (
                <ProfileCard
                  user={mentor?.profile?.user}
                  mentor={mentor}
                  key={index}
                  handleMenteeInterest={handleMenteeInterest}
                />
              );
            })
          : mentees?.map((mentee, index) => {
              return (
                <ProfileCard
                  user={mentee?.profile?.user}
                  mentee={mentee}
                  key={index}
                />
              );
            })}
      </Box>
      {/* Pagination Controls */}
      <Box display="flex" justifyContent="center" marginTop="20px">
        {/* Previous Page Button with Tooltip */}
        <Tooltip label="Previous Page" fontSize="md">
          <IconButton
            onClick={handlePreviousPage}
            icon={<ChevronLeftIcon />}
            size="lg" // Increase button size
            fontSize="2xl" // Increase icon size
            marginRight="10px"
            aria-label="Previous Page"
            colorScheme="blue" // Add color scheme
            _hover={{ bg: "blue.500", transform: "scale(1.1)" }} // Add hover effect
          />
        </Tooltip>

        {/* Next Page Button with Tooltip */}
        <Tooltip label="Next Page" fontSize="md">
          <IconButton
            onClick={handleNextPage}
            icon={<ChevronRightIcon />}
            size="lg" // Increase button size
            fontSize="2xl" // Increase icon size
            aria-label="Next Page"
            colorScheme="blue" // Add color scheme
            _hover={{ bg: "blue.500", transform: "scale(1.1)" }} // Add hover effect
          />
        </Tooltip>
      </Box>
    </Box>
  );
}

export default Searches;
