import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  Link,
  Divider,
  Button,
} from "@chakra-ui/react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const BlogPostNeetClass11thFoundation = () => {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"5%"}
        paddingBottom={{ base: "0%", lg: "5%" }}
        paddingTop={{ base: "40%", lg: "10%" }}
        marginBottom={{ base: "0%", lg: "0%" }}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <VStack spacing="6" align="start">
          <Heading as="h1" size="2xl" color="teal.600">
            Building a Strong Class 11 Foundation for Guaranteed Future Success
          </Heading>
          <Text fontSize="lg" color="gray.700">
            In the journey of academic excellence, the foundation laid in Class
            11 plays a crucial role in determining future success. This
            foundational year is not merely a transition from school to higher
            secondary education but a critical phase where the groundwork for
            future academic achievements is established. At MenteeMentors, we
            understand the importance of this phase and offer tailored solutions
            to help students build a robust foundation for success.
          </Text>
          <Divider marginY="4" borderColor="gray.300" />
          <Heading as="h2" size="xl" color="teal.500">
            Why Class 11 Matters
          </Heading>
          <Text fontSize="lg" color="gray.700">
            Class 11 is a pivotal year where students encounter advanced
            concepts in subjects like Mathematics, Physics, Chemistry, and
            Biology. It is the stage where the academic journey transitions from
            basic concepts to more complex topics that will be critical in Class
            12 and beyond. The concepts learned in Class 11 form the base for
            competitive exams like IIT-JEE, NEET, and other entrance tests,
            making it imperative for students to have a solid understanding.
          </Text>
          <Divider marginY="4" borderColor="gray.300" />
          <Heading as="h2" size="xl" color="teal.500">
            How MenteeMentors Can Help
          </Heading>
          <Text fontSize="lg" color="gray.700">
            At MenteeMentors, we are dedicated to providing students with
            personalized mentorship that addresses their unique needs and
            challenges. Our platform connects students with experienced mentors
            who guide them through their academic journey, offering support in
            the following ways:
          </Text>
          <List spacing="4" fontSize="lg" color="gray.600">
            <ListItem>
              <strong>Personalized Tutoring:</strong> Our mentors offer
              one-on-one tutoring sessions tailored to each student’s pace and
              learning style. This personalized approach ensures that students
              thoroughly understand complex topics and can apply them
              effectively.
            </ListItem>
            <ListItem>
              <strong>Comprehensive Study Plans:</strong> We help students
              create detailed study plans that cover the entire Class 11
              syllabus. Our mentors assist in setting achievable goals and
              provide resources to ensure that students stay on track.
            </ListItem>
            <ListItem>
              <strong>Exam Preparation:</strong> Preparation for competitive
              exams starts early. Our mentors provide guidance on solving
              previous years' papers, taking mock tests, and developing
              effective study strategies.
            </ListItem>
            <ListItem>
              <strong>Skill Development:</strong> Beyond academics, we focus on
              developing essential skills such as problem-solving, critical
              thinking, and time management, which are crucial for both exams
              and future career success.
            </ListItem>
            <ListItem>
              <strong>Support and Motivation:</strong> Our mentors offer ongoing
              support and motivation to help students stay focused and overcome
              any obstacles they may face throughout the year.
            </ListItem>
          </List>
          <Divider marginY="4" borderColor="gray.300" />
          <Heading as="h2" size="xl" color="teal.500">
            Success Stories with MenteeMentors
          </Heading>
          <Text fontSize="lg" color="gray.700">
            Many students who have worked with MenteeMentors have seen
            remarkable improvements in their academic performance and have
            achieved their desired scores in competitive exams. Here are a few
            success stories from our platform:
          </Text>
          <Text fontSize="lg" color="gray.600" fontStyle="italic">
            "With the guidance of my mentor from MenteeMentors, I was able to
            grasp complex concepts in Physics and Chemistry. Their personalized
            approach made a huge difference in my preparation for the IIT-JEE
            exam." – <strong>Aarav Kumar, IIT-JEE Aspirant</strong>
          </Text>
          <Text fontSize="lg" color="gray.600" fontStyle="italic">
            "The study plan and motivational support I received from
            MenteeMentors helped me stay organized and focused. Thanks to my
            mentor, I achieved a top rank in the NEET exam." –{" "}
            <strong>Sanya Patel, NEET Topper</strong>
          </Text>
          <Divider marginY="4" borderColor="gray.300" />
          <Heading as="h2" size="xl" color="teal.500">
            Get Started with MenteeMentors
          </Heading>
          <Text fontSize="lg" color="gray.700">
            If you are a Class 11 student looking to build a strong academic
            foundation and excel in your future endeavors, MenteeMentors is here
            to help. Our platform offers a range of services designed to meet
            your needs and ensure your success. Visit{" "}
            <Link href="https://menteementors.com" color="teal.500" isExternal>
              menteementors.com
            </Link>{" "}
            to learn more about our offerings and get started on your path to
            success.
          </Text>
          <Button
            as="a"
            href="/mentee-registration"
            colorScheme="teal"
            size="lg"
            borderRadius="md"
          >
            Sign Up as a Mentor or Mentee
          </Button>
        </VStack>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default BlogPostNeetClass11thFoundation;
