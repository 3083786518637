import React from "react";
import { Box, Text, Button } from "@chakra-ui/react";

function Plans({ onChoosePlan, isUserLoggedIn, setShouldShowPlan }) {
  const plansArray = [
    {
      plan_intro: "Free Plan",
      planFeatures: ["Unlimited chat with any 3 Mentors"],
      planOriginalPrice: 100,
      planReducedPrice: 0,
      planColor: "#FAF8D9",
      planButtonColor: "#FAF8D9",
    },
    {
      plan_intro: "Starter Plan",
      planFeatures: [
        "You can schedule upto 2 meetings with your personal mentor",
        "Unlimited chat with your 1 Mentor",
        "Validity 2 Days",
      ],
      planOriginalPrice: 499,
      planReducedPrice: 299,
      planButtonAction: "Select Plan",
      planColor: "#FAF8D9",
      planButtonColor: "#FAF8D9",
    },
    {
      plan_intro: "Beginner's Plan",
      planFeatures: [
        "You can schedule upto 20 meetings with your personal mentor",
        "Unlimited chat with your 1 Mentor",
        "Test Series Evaluation with Mentor",
        "Personalised Study Plan",
        "Toppers Hand Written Notes",
        "Validity 1 Month",
      ],
      planOriginalPrice: 5999,
      planReducedPrice: 1999,
      planButtonAction: "Select Plan",
      planColor: "#FAF8D9",
      planButtonColor: "#FAF8D9",
    },
    {
      plan_intro: "Accelerator Plan",
      planFeatures: [
        "You can schedule upto 50 meetings with your personal mentor",
        "Unlimited chat with your 1  Mentor",
        "Test Series Evaluation with Mentor",
        "Personalised Study Plan",
        "Toppers Hand Written Notes",
        "Validity 6 Months",
      ],
      planOriginalPrice: 13499,
      planReducedPrice: 8999,
      planButtonAction: "Select Plan",
      planColor: "#FAF8D9",
      planButtonColor: "#FAF8D9",
    },
    {
      plan_intro: "Mega Plan",
      planFeatures: [
        "You can schedule upto 75 meetings with your personal mentor",
        "Unlimited chat with your 1 Mentor",
        "Test Series Evaluation with Mentor",
        "Personalised Study Plan",
        "Toppers Hand Written Notes",
        "Validity 12 Months",
      ],
      planOriginalPrice: 22499,
      planReducedPrice: 12999,
      planButtonAction: "Select Plan",
      planColor: "#8CC28E",
      planButtonColor: "#FDB34A",
    },
  ];

  return (
    <Box
      paddingTop={"5%"}
      paddingBottom={"20%"}
      height={{ base: "100vh", lg: "100vh" }} // Set height to make overflow work
      overflowY={"auto"} // Make the container scrollable
      pt={{ base: "10vh", lg: "0vh" }}
    >
      <Box
        fontSize={{ base: "3xl", lg: "5xl" }}
        fontWeight={"extrabold"}
        textAlign={"center"}
      >
        <h1>
          Best{" "}
          <Text display={"inline"} textColor={"#FFA150"}>
            Plans
          </Text>{" "}
          for you
        </h1>
        <Box
          height={"5px"}
          width={"200px"}
          bgColor={"#04AB07"}
          margin={"auto"}
        ></Box>
        <Button onClick={() => setShouldShowPlan(false)}>Close</Button>
      </Box>
      <Box
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent={"center"}
        marginTop={{ base: "0px", lg: "50px" }}
        flexWrap={"wrap"}
        id="plans"
        className="plans"
      >
        {plansArray.map((plan, indx) => {
          return (
            <Box
              key={indx}
              bgColor={plan.planColor}
              padding={"20px"}
              borderRadius={"20px"}
              w={
                isUserLoggedIn
                  ? { base: "100%", lg: "50%" }
                  : { base: "100%", lg: "34%" }
              }
              marginRight={{ base: "0%", lg: "2%" }}
              marginTop={"70px"}
              shadow={"0px 0px 20px 0px rgba(0, 0, 0, 0.5)"}
              _hover={{
                transform: "scale(1.05)", // Fix hover scaling
                shadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.8)",
              }}
              transition={"transform 0.2s"} // Smooth transition for hover effect
            >
              <Box
                fontSize={{ base: "xl", lg: "2xl" }}
                fontWeight={"extrabold"}
                marginLeft={"20px"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <h1>{plan.plan_intro}</h1>
                {indx === 2 && (
                  <Text display={"inline"} textColor={"green"}>
                    Bestseller
                  </Text>
                )}
              </Box>
              <Box marginTop={"20px"}>
                {plan.planFeatures.map((feature, index) => (
                  <Box key={index} padding={"5px"} paddingLeft={"0px"}>
                    <i className="fa fa-solid fa-check"></i>
                    <Text
                      display={"inline"}
                      marginLeft={"10px"}
                      fontSize={{ base: "md", lg: "xl" }}
                    >
                      {feature}
                    </Text>
                  </Box>
                ))}
              </Box>
              <Box marginTop={{ base: "20px", lg: "50px" }}>
                <Text textColor={indx === 2 ? "#141414" : "#909F95"}>
                  MRP ₹<del>{plan.planOriginalPrice}</del>/-
                </Text>
                <Text
                  fontSize={{ base: "medium", lg: "xl" }}
                  fontWeight={"extrabold"}
                >
                  Today's Deal ₹{plan.planReducedPrice}/-
                </Text>
              </Box>
              {indx > 0 && isUserLoggedIn && (
                <Box marginTop={{ base: "20px", lg: "50px" }} width={"100%"}>
                  <Button
                    bgColor={plan.planButtonColor}
                    w={"100%"}
                    padding={"25px"}
                    borderRadius={"50px"}
                    border={"1px solid black"}
                    onClick={() => onChoosePlan(plan)}
                  >
                    {plan.planButtonAction}
                  </Button>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default Plans;
