import React, { useState } from "react";
import { Box, Heading, Text, Divider } from "@chakra-ui/react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Navbar from "../common/Navbar";

const Terms = () => {
  const [openNavbar, setOpenNavbar] = useState(false);
  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box
        display={"flex"}
        padding={"5%"}
        paddingBottom={{ base: "0%", lg: "5%" }}
        flexDirection={"column"}
        paddingTop={{ base: "40%", lg: "10%" }}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Heading as="h1" size="lg" mb={4}>
          Terms and Conditions
        </Heading>
        <Text mb={4}>
          These terms and conditions outline the rules and regulations for the
          use of MenteeMentors's website, located at www.menteementors.com.
        </Text>
        <Divider mb={4} />
        <Text mb={4}>
          By accessing this website, we assume you accept these terms and
          conditions. Do not continue to use MenteeMentors if you do not agree
          to take all of the terms and conditions stated on this page.
        </Text>
        <Text mb={4}>
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements: "Client",
          "You" and "Your" refers to you, the person log on this website and
          compliant to the Company’s terms and conditions. "The Company",
          "Ourselves", "We", "Our" and "Us", refers to our Company.
        </Text>
        <Text mb={4}>
          <strong>1. License</strong>
        </Text>
        <Text mb={4}>
          Unless otherwise stated, MenteeMentors and/or its licensors own the
          intellectual property rights for all material on MenteeMentors. All
          intellectual property rights are reserved. You may access this from
          MenteeMentors for your own personal use subjected to restrictions set
          in these terms and conditions.
        </Text>
        <Text mb={4}>
          <strong>2. Restrictions</strong>
        </Text>
        <Text mb={4}>
          You are specifically restricted from all of the following:
        </Text>
        <ul>
          <li>publishing any Website material in any other media;</li>
          <li>
            selling, sublicensing, and/or otherwise commercializing any Website
            material;
          </li>
          <li>publicly performing and/or showing any Website material;</li>
          <li>
            using this Website in any way that is or may be damaging to this
            Website;
          </li>
          <li>
            using this Website in any way that impacts user access to this
            Website;
          </li>
          <li>
            using this Website contrary to applicable laws and regulations, or
            in any way may cause harm to the Website, or to any person or
            business entity;
          </li>
          <li>
            engaging in any data mining, data harvesting, data extracting, or
            any other similar activity in relation to this Website;
          </li>
          <li>using this Website to engage in any advertising or marketing.</li>
        </ul>
        <Text mb={4}>
          Certain areas of this Website are restricted from being accessed by
          you and MenteeMentors may further restrict access by you to any areas
          of this Website, at any time, in absolute discretion. Any user ID and
          password you may have for this Website are confidential and you must
          maintain confidentiality as well.
        </Text>
        <Text mb={4}>
          <strong>3. Your Content</strong>
        </Text>
        <Text>
          In these Website Standard Terms and Conditions, "Your Content" shall
          mean any audio, video text, images, or other material you choose to
          display on this Website. By displaying Your Content, you grant
          MenteeMentors a non-exclusive, worldwide, irrevocable, sublicensable
          license to use, reproduce, adapt, publish, translate, and distribute
          it in any and all media.
        </Text>
        {/* Add more sections for Terms and Conditions */}
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default Terms;
