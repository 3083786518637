import { Box, Button, Img } from "@chakra-ui/react";
import React from "react";

const ContentArray = [
  {
    img: "https://images.pexels.com/photos/7550581/pexels-photo-7550581.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    text: "Track your progress with regular one-on-one meetings with a mentor.",
  },
  {
    img: "https://images.pexels.com/photos/4099321/pexels-photo-4099321.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    text: "Get the best advice by chatting with your mentor.",
  },
  {
    img: "https://images.pexels.com/photos/552774/pexels-photo-552774.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    text: "Avoid wasting time on unimportant tasks or unnecessary things.",
  },
  {
    img: "https://images.pexels.com/photos/3768590/pexels-photo-3768590.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    text: "Stay motivated and on track by regularly checking in and monitoring your progress.",
  },
];

function Helps() {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      textAlign={"center"}
      padding={"20px"}
    >
      <Box textAlign={"center"}>
        <Button
          colorScheme="white"
          variant="outline"
          textColor={"#7FA2FF"}
          borderRadius={"full"}
          border={"1px solid #7FA2FF"}
          _hover={{
            transform: "scale(1.1)",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
          }}
          transition={"transform 0.2s ease, box-shadow 0.2s ease"}
        >
          HOW IT HELPS
        </Button>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        fontSize={{ base: "3xl", lg: "5xl" }}
        fontWeight={"extrabold"}
        marginTop={"20px"}
        marginBottom={"40px"}
      >
        <h1>No need to struggle</h1>
        <h1>alone anymore</h1>
      </Box>
      <Box
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
        flexWrap={"wrap"}
        gap={"20px"}
      >
        {ContentArray?.map((item, index) => (
          <Box
            key={index}
            border={"0px"}
            shadow={"lg"}
            padding={{ base: "20px", lg: "50px" }}
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            fontWeight={"bold"}
            width={{ base: "100%", lg: "40%" }}
            borderRadius={"30px"}
            margin={"10px"}
            fontSize={{ base: "small", lg: "large" }}
            transition={"transform 0.3s ease, box-shadow 0.3s ease"}
            _hover={{
              transform: "perspective(1000px) rotateY(10deg) rotateX(5deg)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Img
              src={item.img}
              alt="mentor-help"
              marginBottom={"30px"}
              borderRadius={"20px"}
              height={"20%"}
            />
            <h1>{item.text}</h1>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Helps;
