import { Box, Button, useBreakpointValue } from "@chakra-ui/react";
import React, { useState, useMemo } from "react";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../utils/MyTable";
import {
  formatDate4,
  getFieldsExcept,
  getFormattedTimeFromDate,
} from "../../utils/utils";

const commonTableStyle = {
  width: {
    base: "25px",
    md: "80px",
    lg: "115px",
  },
  height: {
    base: "15px",
    md: "40px",
    lg: "70px",
  },
  textAlign: "center",
};

const listOfMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const generateCalendarDates = (currentMonth, currentYear) => {
  const calendarDates = [];
  const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);
  const firstDayOfWeek = firstDayOfCurrentMonth.getDay();
  const lastDay = new Date(currentYear, currentMonth + 1, 0);
  const numberOfDays = lastDay.getDate();

  for (let i = 0; i < firstDayOfWeek; i++) {
    calendarDates.push("");
  }
  for (let i = 1; i <= numberOfDays; i++) {
    calendarDates.push(i);
  }
  while (calendarDates.length % 7 !== 0) {
    calendarDates.push("");
  }

  return calendarDates;
};

function Calendar() {
  const bookings = useSelector((state) => state.bookings);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [selectedDateBookings, setSelectedDateBookings] = useState([]);
  const dispatch = useDispatch();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const handleLogoutSubmit = () => {
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    navigate("/");
    dispatch({ type: "Logout", value: {} });
  };

  const onClickToday = () => {
    setCurrentMonth(today.getMonth());
    setCurrentYear(today.getFullYear());
  };

  const calendarDates = useMemo(
    () => generateCalendarDates(currentMonth, currentYear),
    [currentMonth, currentYear]
  );

  const currentMonthBookings = useMemo(() => {
    return (
      bookings?.filter((meeting) => {
        const meetingDate = new Date(meeting.scheduledTime);
        return (
          meetingDate.getMonth() === currentMonth &&
          meetingDate.getFullYear() === currentYear
        );
      }) || []
    );
  }, [bookings, currentMonth, currentYear]);

  const currentMonthBookingDates = useMemo(() => {
    return (
      currentMonthBookings?.map((meeting) => {
        return new Date(meeting.scheduledTime).getDate();
      }) || []
    );
  }, [currentMonthBookings]);

  const handleShowMeetings = (date) => {
    const filteredMeetings = currentMonthBookings.filter((meeting) => {
      return new Date(meeting.scheduledTime).getDate() === date;
    });
    const newModifiedDateMeetings = filteredMeetings.map((filteredMeeting) => {
      return {
        ...filteredMeeting,
        createdAt: getFormattedTimeFromDate(filteredMeeting.createdAt),
      };
    });
    setSelectedDateBookings(newModifiedDateMeetings);
  };

  const handleCloseTable = () => {
    setSelectedDateBookings([]);
  };

  const handleRowSelection = () => {
    console.log("booking row clicked");
  };

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      justifyContent={"space-between"}
      margin={{ base: "5%", lg: "5%" }}
    >
      <Box
        opacity={selectedDateBookings.length !== 0 ? "0.1" : "1"}
        minWidth="20%"
      >
        <Navbar />
      </Box>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          margin="20px 10px"
          justifyContent="space-between"
          opacity={selectedDateBookings.length !== 0 ? "0.1" : "1"}
        >
          <Box fontSize={{ base: "xl", lg: "2xl" }} fontWeight="bold">
            <h1>Welcome {user?.name}</h1>
          </Box>
          <Button
            onClick={handleLogoutSubmit}
            bg="#FCB339"
            _hover={{
              backgroundColor: "#FCB339",
              color: "white",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            }}
          >
            Logout
          </Button>
        </Box>
        <Box
          width="100%"
          shadow="0px 0px 20px 0px rgba(0, 0, 0, 0.5)"
          border="1px solid #D2D2D2"
          padding={{ sm: "20px 10px", md: "30px 40px", lg: "40px 50px" }}
          borderRadius="20px"
          opacity={selectedDateBookings.length !== 0 ? "0.1" : "1"}
        >
          <CalendarHeader
            currentMonth={currentMonth}
            currentYear={currentYear}
            setCurrentMonth={setCurrentMonth}
            onClickToday={onClickToday}
          />
          <Box
            py="40px"
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
          >
            <CalendarTable
              calendarDates={calendarDates}
              today={today}
              currentMonth={currentMonth}
              currentMonthBookingDates={currentMonthBookingDates}
              handleShowMeetings={handleShowMeetings}
            />
          </Box>
        </Box>
        {selectedDateBookings.length !== 0 && (
          <Box
            position="absolute"
            top="25%"
            left={{ base: "1%", lg: "25%" }}
            bg="white"
            zIndex="1000"
            opacity="1000"
          >
            <DataTable
              title={`${formatDate4(
                selectedDateBookings[0].scheduledTime
              )} Bookings`}
              data={selectedDateBookings}
              showClose={true}
              handleRowSelection={handleRowSelection}
              closeTable={handleCloseTable}
              maxWidth={{ base: "40px", md: "40px", lg: "200px" }}
              headers={getFieldsExcept(
                selectedDateBookings[0],
                "__v",
                "_id",
                "organizer",
                "scheduledTime",
                "attendees",
                "meetingType",
                "updatedAt",
                "platform",
                `${isMobile ? "durationMinutes" : ""}`
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

const CalendarHeader = ({
  currentMonth,
  currentYear,
  setCurrentMonth,
  onClickToday,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      p={{ base: "10px 20px", md: "0px" }}
    >
      <Box display="flex" flexDirection="row">
        <Button
          borderTopRightRadius="0px"
          borderBottomRightRadius="0px"
          bg="#FCB339"
          _hover={{
            backgroundColor: "#FCB339",
            color: "white",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
          onClick={() => setCurrentMonth((currentMonth - 1 + 12) % 12)}
        >
          <i className="fa fa-solid fa-chevron-left"></i>
        </Button>
        <Button
          borderTopLeftRadius="0px"
          borderBottomLeftRadius="0px"
          bg="#FCB339"
          _hover={{
            backgroundColor: "#FCB339",
            color: "white",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
          onClick={() => setCurrentMonth((currentMonth + 1) % 12)}
        >
          <i className="fa fa-solid fa-chevron-right"></i>
        </Button>
        <Button
          bg="#FCB339"
          _hover={{
            backgroundColor: "#FCB339",
            color: "white",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
          marginLeft={{ base: "10px", lg: "30px" }}
          onClick={onClickToday}
        >
          Today
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        ml={{ base: "10px", lg: "0px" }}
        fontSize={{ base: "sm", lg: "2xl" }}
        fontWeight="bold"
      >
        <h1>{listOfMonths[currentMonth]}</h1>
        <Box display="inline" marginLeft="10px">
          <h1>{currentYear}</h1>
        </Box>
      </Box>
    </Box>
  );
};

const CalendarTable = ({
  calendarDates,
  today,
  currentMonth,
  currentMonthBookingDates,
  handleShowMeetings,
}) => {
  const weeks = [];
  for (let i = 0; i < calendarDates.length; i += 7) {
    weeks.push(calendarDates.slice(i, i + 7));
  }
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <table>
      <thead>
        <tr>
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
            <td key={day}>
              <Box sx={commonTableStyle} fontSize={isMobile ? "x-small" : "md"}>
                {day}
              </Box>
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeks.map((week, weekIndex) => (
          <tr key={weekIndex}>
            {week.map((date, dateIndex) => (
              <CalendarCell
                key={dateIndex}
                date={date}
                today={today}
                currentMonth={currentMonth}
                currentMonthBookingDates={currentMonthBookingDates}
                handleShowMeetings={handleShowMeetings}
                commonTableStyle={commonTableStyle}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const CalendarCell = ({
  date,
  today,
  currentMonth,
  currentMonthBookingDates,
  handleShowMeetings,
  commonTableStyle,
}) => {
  const isToday = date === today.getDate() && currentMonth === today.getMonth();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <td>
      <Box
        sx={commonTableStyle}
        textColor={isToday ? "#0E6CFD" : "inherit"}
        fontWeight={isToday ? "extrabold" : "normal"}
        fontSize={isToday ? (isMobile ? "xl" : "5xl") : "md"}
        mb={isToday ? (isMobile ? "5px" : "0px") : "0px"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        {date && currentMonthBookingDates.includes(date) && (
          <Button
            onClick={() => handleShowMeetings(date)}
            display="block"
            bg="#FCB339"
            fontSize={{ base: "xx-small", lg: "large" }}
            _hover={{
              backgroundColor: "#FCB339",
              color: "white",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            }}
            mb="0px"
            pb="0px"
          >
            Meetings
          </Button>
        )}
        {date}
      </Box>
    </td>
  );
};

export default Calendar;
