import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import Intro from "../home/Intro";
import Helps from "../home/Helps";
import Steps from "../home/Steps";
import Reviews from "../home/Reviews";

function Home() {
  const [openNavbar, setOpenNavbar] = useState(false);
  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box opacity={openNavbar ? "0.1" : "1"}>
        <Box
          padding={{ base: "1%", lg: "3%" }}
          paddingTop={{ base: "40%", lg: "3%" }}
        >
          <Intro></Intro>
        </Box>
        <Box padding={{ base: "1%", lg: "3%" }}>
          <Helps></Helps>
        </Box>
        <Box padding={{ base: "1%", lg: "3%" }}>
          <Steps></Steps>
        </Box>
        <Box padding={{ base: "0%", lg: "3%" }}>
          <Reviews></Reviews>
        </Box>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
}

export default Home;
