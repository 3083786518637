import { Box, Button, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Navbar from "./Navbar";
import DataTable from "../utils/MyTable";
import { getFieldsExcept } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Bookings() {
  const bookings = useSelector((state) => state.bookings);
  const user = useSelector((state) => state.user);
  const [filter, setFilter] = useState("All");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRowSelection = () => {
    console.log("booking row clicked");
  };

  const filterBookings = () => {
    const now = new Date();
    switch (filter) {
      case "Upcoming":
        return bookings.filter(
          (booking) => new Date(booking.scheduledTime) > now
        );
      case "Today":
        return bookings.filter(
          (booking) =>
            new Date(booking.scheduledTime).toDateString() ===
            now.toDateString()
        );
      case "Completed":
        return bookings.filter(
          (booking) =>
            new Date(booking.scheduledTime) <= now &&
            booking.attendees.some(
              (attendee) => attendee.userId === user._id && attendee.joined
            )
        );
      case "Cancelled":
        return bookings.filter(
          (booking) =>
            new Date(booking.scheduledTime) <= now &&
            booking.attendees.some(
              (attendee) => attendee.userId === user._id && !attendee.joined
            )
        );
      default:
        return bookings;
    }
  };

  const handleLogout = () => {
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    navigate("/");
    dispatch({ type: "Logout", value: {} });
  };

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      margin="5%"
      justifyContent={"space-between"}
    >
      <Navbar />
      <Box flex="1" marginTop="5%" maxW={{ base: "100%", lg: "80%" }}>
        <Box
          fontSize="4xl"
          fontWeight="bold"
          marginBottom="20px"
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignContent={"space-between"}
        >
          <h1>My Meetings</h1>
          <Button
            onClick={handleLogout}
            mt={{ base: 4, lg: 0 }}
            bg="#FCB339"
            _hover={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
            transition="box-shadow 0.3s"
          >
            Logout
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row" }}
          marginBottom="20px"
        >
          {["All", "Upcoming", "Today", "Completed", "Cancelled"].map(
            (label) => (
              <Button
                key={label}
                margin="10px"
                backgroundColor={filter === label ? "#FCB339" : "#FFFFFF"}
                color={"black"}
                _hover={{
                  backgroundColor: "#FCB339",
                  color: "white",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                }}
                border="1px solid #FCB339"
                transition="box-shadow 0.3s, background-color 0.3s"
                onClick={() => setFilter(label)}
              >
                {label}
              </Button>
            )
          )}
        </Box>
        {filterBookings().length === 0 && (
          <Text textAlign={"center"} mt="20%" fontSize={"x-large"}>
            No Meetings Present
          </Text>
        )}
        {filterBookings().length > 0 && (
          <Box marginTop="50px" fontSize="small">
            <DataTable
              title="Meetings List"
              data={filterBookings()}
              handleRowSelection={handleRowSelection}
              maxWidth="200px"
              headers={getFieldsExcept(
                bookings[0],
                "__v",
                "_id",
                "organizer",
                "attendees",
                "meetingType",
                "createdAt",
                "updatedAt"
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Bookings;
