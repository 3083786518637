export const emptyObject = { dummyField: "dummyFieldValue" };
export const emptyArray = ["dummyElement"];
export const appState = {
  user: emptyObject,
  loginPhoneNumber: "",
  loginEmail: "",
  mentor: emptyObject,
  mentee: emptyObject,
  education: emptyObject,
  examsQualified: emptyObject,
  address: emptyObject,
  meetings: emptyArray,
  chatMessages: emptyArray,
  bookings: emptyArray,
  availability: emptyObject,
  mentors: emptyArray,
  mentees: emptyArray,
  conversations: emptyArray,
  conversationSearchName: "",
};
