import React from "react";
import { useSelector } from "react-redux";
import MentorProfile from "./MentorProfile";
import MenteeProfile from "./MenteeProfile";

function Profile() {
  const mentor = useSelector((state) => state.mentor);

  return (
    <>
      {mentor ? (
        <MentorProfile> </MentorProfile>
      ) : (
        <MenteeProfile></MenteeProfile>
      )}
    </>
  );
}

export default Profile;
