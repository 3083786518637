import React from "react";
import { Box, Img, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const BecomeMentorIntro = () => (
  <Box
    display="flex"
    flexDirection={{ base: "column", lg: "row" }}
    mt={{ base: "20px", lg: "50px" }}
    alignItems="center"
  >
    <Img
      src="https://media.licdn.com/dms/image/v2/D5603AQGpaub_7GEQ9w/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1669360681171?e=1729728000&v=beta&t=QOaQ-nkwhdz8co6xMvEHyA6W0DO-Fe0SiinqbJoMjSI"
      alt="Mentor"
      width={{ base: "90%", lg: "40%" }}
      borderRadius="lg"
      mb={{ base: "20px", lg: "0" }}
    />
    <Box display="flex" flexDirection="column" pl={{ base: "0", lg: "30px" }}>
      <Box
        fontSize={{ base: "xl", lg: "3xl" }}
        fontWeight="extrabold"
        mb="20px"
      >
        <h1>
          MenteeMentors is a game-changing <br /> platform for both mentors &
          mentees
        </h1>
      </Box>
      <Box fontSize={{ base: "md", lg: "xl" }} fontWeight="light" mb="20px">
        <p>
          "Setting goals is one thing, but knowing how to achieve them is
          another. MenteeMentors mentors come from top Colleges. They understand
          exactly what the exam requires you to do. They're the best people to
          help you find the right way forward."
        </p>
      </Box>
      <Box fontSize="xl" fontWeight="extrabold" mb="20px">
        <h4>Himanshu Gaud</h4>
        <h5>IIT Delhi</h5>
      </Box>
      <Button
        as={Link}
        to="/mentor-registration"
        bg="#000000"
        color="white"
        border="2px solid white"
        fontWeight="extrabold"
        maxW={{ base: "60%", lg: "30%" }}
        my="4%"
        _hover={{ bg: "green.300", color: "black" }}
      >
        Sign Up as Mentor
      </Button>
    </Box>
  </Box>
);

export default BecomeMentorIntro;
