import {
  Box,
  Button,
  HStack,
  Image,
  PinInput,
  PinInputField,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Carousal from "../utils/Carousal";
import { hitBeApi } from "../../apis/api";
import { useSelector } from "react-redux";
import { isCookieExpired } from "../../utils/utils";

function VerifyOtp() {
  const navigate = useNavigate();
  const phoneNumberInput = useSelector((state) => state.loginPhoneNumber);
  const emailInput = useSelector((state) => state.loginEmail);
  const toast = useToast();
  const [pin, setPin] = useState("");
  const [timer, setTimer] = useState(59);
  const [isResendBlocked, setIsResendBlocked] = useState(true);

  const pinInputRefs = useRef([]);

  // Define color mode values outside the Box component
  const bgColor = useColorModeValue("white", "gray.800");
  const textColorPrimary = useColorModeValue("black", "white");
  const textColorHighlight = useColorModeValue("#069254", "#0ef04a");
  const buttonBgColor = useColorModeValue("#FFB934", "#FF8C00");
  const shadowColor = useColorModeValue(
    "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
    "0px 0px 20px 0px rgba(0, 0, 0, 0.5)"
  );

  useEffect(() => {
    if (!isCookieExpired("token")) {
      navigate("/dashboard");
    }
    if (phoneNumberInput.length === 0 && emailInput.length === 0) {
      navigate("/login");
    }
  }, [emailInput.length, navigate, phoneNumberInput.length]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(countdown);
          setIsResendBlocked(false);
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (pinInputRefs.current[0]) {
      pinInputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = async (e) => {
      if (e.key === "Enter") {
        await verifyOtp();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const handlePinChange = (value) => {
    setPin(value);
  };

  const handleResendOtp = async () => {
    try {
      await hitBeApi("phone/otp", "POST", { phoneNumber: phoneNumberInput });
      toast({
        title: "OTP re-sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsResendBlocked(true);
      setTimer(59);
    } catch (error) {
      toast({
        title: "Error re-sending OTP.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const verifyOtp = async () => {
    if (emailInput.length === 0 && phoneNumberInput.length === 0) {
      toast({
        title: "Error",
        description: "Please provide a phone number or email first.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (pin.length !== 6) {
      toast({
        title: "Error",
        description: "OTP must be 6 digits.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await hitBeApi(
        `${emailInput ? "email" : "phone"}/otp/verify`,
        "POST",
        {
          phoneNumber: phoneNumberInput,
          email: emailInput,
          otp: parseInt(pin),
        }
      );

      if (response.success) {
        document.cookie = `token=${response?.data?.token}; path=/;`;
        navigate("/dashboard");
      } else {
        toast({
          title: "Error",
          description: "Incorrect OTP.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      margin="5% 5%"
      justifyContent="space-between"
    >
      <Box
        bg={bgColor}
        padding="40px"
        borderRadius="30px"
        width={{ base: "100%", lg: "45%" }}
        shadow={shadowColor}
        flexDirection="column"
      >
        <Box
          margin="10px 0px"
          fontSize={{ base: "3xl", lg: "5xl" }}
          fontWeight="extrabold"
          textAlign={{ base: "left", lg: "center" }}
          color={textColorPrimary}
        >
          <Link to="/">
            <Image
              height={"40px"}
              src="/logo.png"
              alt="logo"
              mr="5px"
              display={"inline"}
            ></Image>
            MenteeMentors
          </Link>
        </Box>
        <Box
          margin="5px 0px"
          fontSize={{ base: "2xl", lg: "4xl" }}
          fontWeight="extrabold"
          color={textColorPrimary}
        >
          <h1>Enter OTP</h1>
        </Box>
        <Box
          margin="0px 0px"
          fontSize={{ base: "sm", lg: "xl" }}
          fontWeight="normal"
          color={textColorPrimary}
        >
          <h1>We've sent an OTP to your registered</h1>
        </Box>
        <Box
          margin="5px 0px"
          marginTop="5px"
          fontSize="msl"
          fontWeight="normal"
          color={textColorPrimary}
        >
          {phoneNumberInput ? (
            <h1>Mobile No. {phoneNumberInput}</h1>
          ) : (
            <h1>Email. {emailInput}</h1>
          )}
        </Box>
        <Box
          margin="0px 0px"
          fontSize="xl"
          fontWeight="extrabold"
          color={textColorPrimary}
        >
          <h1>Enter One Time Password</h1>
        </Box>
        <Box margin="15px 0px">
          <HStack>
            <PinInput size="lg" value={pin} onChange={handlePinChange}>
              {Array(6)
                .fill("")
                .map((_, i) => (
                  <PinInputField
                    key={i}
                    ref={(el) => (pinInputRefs.current[i] = el)}
                    bg={bgColor}
                    color={textColorPrimary}
                    shadow="md"
                    _focus={{ borderColor: textColorHighlight }}
                  />
                ))}
            </PinInput>
          </HStack>
        </Box>
        <Box
          margin="10px 0px"
          fontSize={{ base: "sm", lg: "xl" }}
          display="flex"
          justifyContent="space-between"
          color={textColorHighlight}
        >
          <Box
            onClick={!isResendBlocked ? handleResendOtp : undefined}
            cursor={isResendBlocked ? "not-allowed" : "pointer"}
          >
            Resend Code {isResendBlocked && `(${timer}s)`}
          </Box>
          <Link to="/login">
            Change {phoneNumberInput ? "Mobile No." : "Email"}
          </Link>
        </Box>
        <Box width="100%" margin={{ base: "5px 0px", lg: "10px 0px" }}>
          <Button
            width="100%"
            backgroundColor={buttonBgColor}
            _hover={{ backgroundColor: buttonBgColor }}
            fontSize="2xl"
            padding={{ base: "20px", lg: "30px" }}
            fontWeight="extrabold"
            onClick={verifyOtp}
            shadow="md"
          >
            Verify OTP
          </Button>
        </Box>
      </Box>
      <Box
        width={{ base: "100%", lg: "50%" }}
        padding="20px"
        borderRadius="30px"
        marginTop={{ base: "10%", lg: "0px" }}
        shadow={shadowColor}
      >
        <Carousal />
      </Box>
    </Box>
  );
}

export default VerifyOtp;
