import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Heading,
  VStack,
  Avatar,
  Divider,
  Badge,
  Button,
  Select,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { hitBeApi } from "../../apis/api";
import { useDispatch, useSelector } from "react-redux";
import { formatDate3, generateTimeOptions } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const ProfileCard = ({ mentee, user, mentor, handleMenteeInterest }) => {
  const [isScheduleVisible, setIsScheduleVisible] = useState(false);
  const [availability, setAvailability] = useState([]);
  const userProfile = useSelector((state) => state.user);
  const [availableTimes, setAvailableTimes] = useState([]);
  const userMenteeProfile = useSelector((state) => state.mentee);
  const [meetingDetails, setMeetingDetails] = useState({
    type: "",
    date: "",
    timeSlot: "",
    startTime: "",
    duration: "",
  });
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startTimeOptions, setStartTimeOptions] = useState([]);
  const [isMeetingScheduleInProgress, setIsMeetingScheduleInProgress] =
    useState(false);

  const isPaymentDone = userMenteeProfile && userMenteeProfile.isPaymentDone;

  const isPlanExpired =
    userMenteeProfile &&
    Date.now() > new Date(userMenteeProfile.planExpiryDate).getTime();
  const hasMeetingsScheduledLimitExceeded =
    userMenteeProfile &&
    userMenteeProfile.totalMeetingsScheduledCount >=
      userMenteeProfile.maxMeetingsScheduleLimit;

  const isUserFreeUseageExceeded =
    userMenteeProfile &&
    userMenteeProfile.freeUseageCount &&
    userMenteeProfile.freeUseageCount > 3;

  const doesMenteeRequireNewPlan =
    userMenteeProfile &&
    (userMenteeProfile.totalMeetingsScheduledCount >=
      userMenteeProfile.maxMeetingsScheduleLimit ||
      (userMenteeProfile.planExpiryDate &&
        new Date() > new Date(userMenteeProfile.planExpiryDate)));

  const isMenteeMentorConnected =
    userMenteeProfile &&
    userMenteeProfile.mentorId &&
    userMenteeProfile.mentorId === mentor?._id;

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const response = await hitBeApi(`availability/${user?._id}`, "GET");
        setAvailability(response?.data?.availabilitySlots || []);
      } catch (error) {
        toast({
          title: "Error fetching user availability.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error fetching user availability:", error);
      }
    };

    if (user?._id) {
      fetchAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  const handleDateChange = (date) => {
    setMeetingDetails({ ...meetingDetails, date });
    const selectedDate = new Date(date).getDay();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const selectedDay = daysOfWeek[selectedDate];

    if (
      !availability ||
      availability === undefined ||
      (availability && availability.length === 0)
    ) {
      toast({
        title: "No availability slot found.",
        description: `Please ask ${user.name} to add a availability`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const times = availability
      ?.filter(
        (slot) =>
          slot.dayOfWeek === selectedDay || formatDate3(slot.date) === date
      )
      ?.map((slot) => ({ start: slot.startTime, end: slot.endTime }));

    if (times.length === 0) {
      toast({
        title: "No time slots found.",
        description: "Please select a different date or weekday.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }

    setAvailableTimes(times);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleScheduleMeeting = async () => {
    if (isMeetingScheduleInProgress) {
      return;
    }
    try {
      setIsMeetingScheduleInProgress(true);
      if (
        userMenteeProfile &&
        userMenteeProfile.totalMeetingsScheduledCount >=
          userMenteeProfile.maxMeetingsScheduleLimit
      ) {
        toast({
          title: "Error.",
          description:
            "You have exceeded all limits under your current payment plan, please buy a new plan",
          status: "error",
          duration: 8000,
          isClosable: true,
        });
        return;
      }
      const { type, date, startTime, duration } = meetingDetails;
      if (!type || !date || !startTime || !duration) {
        setIsMeetingScheduleInProgress(false);
        toast({
          title: "Error",
          description: "Please provide all required fields.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const selectedDateTime = new Date(
        `${meetingDetails.date}T${startTime}:00`
      );

      // Check if selected date and time is less than current date and time
      const currentDateTime = new Date();
      if (selectedDateTime < currentDateTime) {
        setIsMeetingScheduleInProgress(false);
        toast({
          title: "Error",
          description: "Selected date and time cannot be in the past.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const endDateTime = new Date(
        selectedDateTime.getTime() + meetingDetails.duration * 60000
      );
      let meetingUrl = "";

      const attendeesList = [];
      attendeesList.push({
        userId: user?._id,
        email: user?.email,
        name: user?.name,
      });
      attendeesList.push({
        userId: userProfile?._id,
        email: userProfile?.email,
        name: userProfile?.name,
      });

      if (meetingDetails.type === "google-meet") {
        const googleMeetPayload = {
          startDate: selectedDateTime.toISOString(),
          endDate: endDateTime.toISOString(),
          attendees: attendeesList,
        };
        const response = await hitBeApi(
          "google/meet",
          "POST",
          googleMeetPayload
        );
        meetingUrl = response.data.eventLink;
      } else if (meetingDetails.type === "zoom") {
        const zoomMeetingPayload = {
          topic: "Demo Meeting",
          type: 2,
          start_time: selectedDateTime.toISOString(),
          duration: meetingDetails.duration,
          settings: {
            join_before_host: true,
            host_video: true,
            participant_video: true,
            mute_upon_entry: false,
            watermark: false,
          },
        };
        const response = await hitBeApi(
          "zoom/meet",
          "POST",
          zoomMeetingPayload
        );
        meetingUrl = response.data.join_url;
      }

      const meetingPayload = {
        userId: user.userId,
        platform: meetingDetails.type,
        scheduledTime: selectedDateTime,
        durationMinutes: meetingDetails.duration,
        meetingLink: meetingUrl,
        attendees: attendeesList,
        organizer: userProfile?._id,
        meetingType: "Scheduled",
      };

      await hitBeApi("meeting", "POST", meetingPayload);
      toast({
        title: "Meeting scheduled successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      userMenteeProfile.totalMeetingsScheduledCount =
        userMenteeProfile.totalMeetingsScheduledCount + 1;
      const updatedMenteeRes = await hitBeApi(
        `mentee/${userMenteeProfile?._id}`,
        "PUT",
        userMenteeProfile
      );
      dispatch({ type: "Set_Mentee", value: updatedMenteeRes?.data });
      setIsScheduleVisible(false);
      setIsMeetingScheduleInProgress(false);
      refreshPage();
    } catch (error) {
      toast({
        title: "Error scheduling meeting.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsMeetingScheduleInProgress(false);
      console.error("Error scheduling meeting:", error);
    }
  };

  const handleShowScheduleMeetingDiv = () => {
    if (
      !isPaymentDone ||
      (isPaymentDone && (isPlanExpired || hasMeetingsScheduledLimitExceeded))
    ) {
      toast({
        title: "Error scheduling meeting.",
        description: "Please buy a plan to schedule meeting with mentor",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsScheduleVisible(true);
  };

  const handleChatWithMentorAction = () => {
    dispatch({
      type: "Set_Conversation_Search_Name",
      value: user?.name,
    });
    navigate("/dashboard/messages");
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="6"
      boxShadow="md"
      bg="white"
      margin={"2%"}
      position="relative"
      display={mentee || mentor ? "block" : "none"}
      w={{ base: "90%", lg: "27%" }}
    >
      <VStack spacing="4" align="flex-start">
        <Box
          _hover={{
            transform: "scale(1.7)", // Increase the size on hover
            transition: "transform 1.4s ease-in-out", // Smooth transition effect
          }}
        >
          <Avatar
            name={user?.name}
            src={user?.imageUrl}
            height="80px"
            width="80px"
            borderRadius="full"
            mb="4"
          />
        </Box>
        <Heading as="h3" size="lg">
          {user?.name}
        </Heading>
        <Divider />
        {mentee && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="teal" mr="2">
              Target
            </Badge>{" "}
            {mentee?.goals[0]}
          </Text>
        )}

        {mentee && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="teal" mr="2">
              State
            </Badge>{" "}
            {mentee?.profile?.address?.state}
          </Text>
        )}

        {mentee && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="teal" mr="2">
              City
            </Badge>{" "}
            {mentee?.profile?.address?.city}
          </Text>
        )}

        {mentee && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="teal" mr="2">
              Class Standard
            </Badge>{" "}
            {mentee?.profile?.education?.educationLevels[0].level}
          </Text>
        )}

        {mentee && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="teal" mr="2">
              Languages
            </Badge>{" "}
            {user?.languages}
          </Text>
        )}

        {mentee && (
          <Text fontSize={{ base: "x-small", lg: "small" }}>
            <Badge colorScheme="teal" mr="2">
              Requirement:
            </Badge>{" "}
            {mentee?.helpNeeded}
          </Text>
        )}

        {mentor && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="teal" mr="2">
              Mentor For
            </Badge>{" "}
            {mentor?.profileType}
          </Text>
        )}

        {mentor && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="teal" mr="2">
              College Name
            </Badge>{" "}
            {mentor?.profile?.education?.educationLevels[0]?.instituteName}
          </Text>
        )}
        {mentor && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="blue" mr="2">
              Languages
            </Badge>{" "}
            {user?.languages || "English"}
          </Text>
        )}
        {mentor && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="blue" mr="2">
              State
            </Badge>{" "}
            {mentor?.profile?.address?.state || "English"}
          </Text>
        )}
        {mentor && (
          <Text fontSize={{ base: "small", lg: "large" }}>
            <Badge colorScheme="blue" mr="2">
              City
            </Badge>{" "}
            {mentor?.profile?.address?.city || "English"}
          </Text>
        )}

        {userMenteeProfile && isUserFreeUseageExceeded ? (
          <Box>
            <Button
              colorScheme="blue"
              onClick={() => handleMenteeInterest(mentor?._id)}
            >
              Buy Long Term Mentorship ?
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              colorScheme="blue"
              onClick={() => handleMenteeInterest(mentor?._id)}
              size={"sm"}
              display={
                isMenteeMentorConnected && !doesMenteeRequireNewPlan
                  ? "none"
                  : "block"
              }
            >
              {doesMenteeRequireNewPlan ? "Extend" : "Buy"} Long Term Mentorship
              ?
            </Button>
            <Button
              onClick={() => handleShowScheduleMeetingDiv()}
              colorScheme="blue"
              mt="10px"
              display={isMenteeMentorConnected ? "block" : "none"}
            >
              Schedule a Meeting
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleChatWithMentorAction}
              mt="10px"
            >
              Chat with {mentor ? "Mentor" : "Mentee"}
            </Button>
          </Box>
        )}
      </VStack>

      {isScheduleVisible && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0.7)"
          zIndex="1000"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            bg="white"
            p="8"
            borderRadius="md"
            boxShadow="lg"
            maxWidth="400px"
            width="90%"
          >
            <VStack spacing="4">
              <Heading as="h3" size="lg" mb="4">
                Schedule Meeting
              </Heading>
              <FormControl id="meeting-type">
                <FormLabel>Meeting Type</FormLabel>
                <Select
                  placeholder="Select meeting type"
                  value={meetingDetails.type}
                  onChange={(e) =>
                    setMeetingDetails({
                      ...meetingDetails,
                      type: e.target.value,
                    })
                  }
                >
                  <option value="google-meet">Google Meet</option>
                  <option value="zoom">Zoom</option>
                </Select>
              </FormControl>
              <FormControl id="meeting-date">
                <FormLabel>Meeting Date</FormLabel>
                <Input
                  type="date"
                  value={meetingDetails.date}
                  onChange={(e) => handleDateChange(e.target.value)}
                />
              </FormControl>
              <FormControl id="meeting-time">
                <FormLabel>Meeting Slot</FormLabel>
                <Select
                  placeholder="Select time"
                  value={meetingDetails.timeSlot}
                  onChange={(e) => {
                    setMeetingDetails({
                      ...meetingDetails,
                      timeSlot: availableTimes[e.target.value]?.time,
                    });
                    setStartTimeOptions(
                      generateTimeOptions(
                        availableTimes[e.target.value].start,
                        availableTimes[e.target.value].end
                      )
                    );
                  }}
                >
                  {availableTimes?.map((time, index) => (
                    <option key={index} value={index}>
                      {time.start} - {time.end}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Select Start Time</FormLabel>
                <Select
                  placeholder="Select time"
                  value={meetingDetails.startTime}
                  onChange={(e) =>
                    setMeetingDetails({
                      ...meetingDetails,
                      startTime: e.target.value,
                    })
                  }
                >
                  {startTimeOptions?.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl id="meeting-duration">
                <FormLabel>Meeting Duration (minutes)</FormLabel>
                <Input
                  type="number"
                  placeholder="Duration"
                  value={meetingDetails.duration}
                  onChange={(e) =>
                    setMeetingDetails({
                      ...meetingDetails,
                      duration: e.target.value,
                    })
                  }
                />
              </FormControl>
              <Button colorScheme="blue" onClick={handleScheduleMeeting}>
                {isMeetingScheduleInProgress ? (
                  <Spinner size={"sm"}></Spinner>
                ) : (
                  "Schedule"
                )}
              </Button>
              <Button onClick={() => setIsScheduleVisible(false)}>
                Cancel
              </Button>
            </VStack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProfileCard;
