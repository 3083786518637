import { format, parseISO } from "date-fns";
import { emptyArray, emptyObject } from "../state/state";

// Validate phone number (10 digits, not starting with 0)
export function validatePhoneNumberWithCountryCode(phoneNumber) {
  const phoneRegex = /^\+[0-9]{1,3}[0-9]{10}$/;
  return phoneRegex.test(phoneNumber);
}

// Check if an object is not empty
export function isObjectNotEmpty(obj) {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }
  return Object.values(obj).every((value) => value);
}

// Extract first and last name
export function getFirstNameAndLastName(fullName) {
  if (!fullName) {
    fullName = "Your Name";
  }
  const words = fullName.trim().split(/\s+/);
  const firstName = words[0];
  const lastName = words[words.length - 1];
  return { firstName, lastName };
}

// Make a string more readable
export function makeReadable(string) {
  return string
    .replace(/([A-Z])/g, " $1")
    .replace(/_/g, " ")
    .replace(/^./, (str) => str.toUpperCase());
}

// Check if a cookie is expired
export const isCookieExpired = (cookieName) => {
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieName));
  if (!cookie) {
    return true;
  }
  const [, value] = cookie.split("=");
  const expirationDate = new Date(value);
  return expirationDate < new Date();
};

// Get cookie token
export const getCookieToken = () => {
  const cookieString = document.cookie;
  if (!cookieString) {
    return "";
  }
  const token = cookieString
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];
  return token;
};

// Convert date string to readable format
export const getReadableFormatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

// Convert 24-hour time to 12-hour format
export const convertTo12HourFormat = (time24) => {
  const [hours24, minutes] = time24.split(":");
  const hours = parseInt(hours24, 10);
  const period = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  return `${hours12}:${minutes} ${period}`;
};

// Validate time format HH:MM
export const isValidTimeFormat = (timeString) => {
  const timePattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
  return timePattern.test(timeString);
};

// Get fields except specified ones
export const getFieldsExcept = (obj, ...fieldsToExclude) => {
  return Object.keys(obj).filter((key) => !fieldsToExclude.includes(key));
};

// Get only specified fields
export const getFieldsOnly = (obj, ...fieldsToInclude) => {
  return Object.keys(obj).filter((key) => fieldsToInclude.includes(key));
};

// Get first name from full name
export function getFirstName(fullName) {
  const nameParts = fullName?.trim().split(" ");
  return nameParts?.[0] || "";
}

// Format ISO time to 12-hour format
export const formatTime = (isoString) => {
  const date = parseISO(isoString);
  return format(date, "h:mm a");
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Helper function to get the ordinal suffix for a day
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const day = date.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Extracting hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format

  return `${day}${getOrdinalSuffix(
    day
  )} ${month} ${year}, ${hours}:${minutes} ${ampm}`;
};

export const formatDate2 = (dateString) => {
  const date = new Date(dateString);

  // Get day, month and year
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Function to get ordinal suffix
  const getOrdinalSuffix = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
  };

  // Construct formatted date
  const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  return formattedDate;
};

export const formatDate3 = (dateString) => {
  // Create a new Date object from the input date string
  const date = new Date(dateString);

  // Get the year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, "0"); // Pad single digit days with a leading zero

  // Return the formatted date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
};

export const formatDate4 = (dateString) => {
  const date = new Date(dateString);

  // Helper function to get the ordinal suffix for a day
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const day = date.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
};

export const groupMessagesByDate = (messages) => {
  return messages.reduce((groups, message) => {
    const date = formatDate4(message.timestamp);
    if (!groups.has(date)) {
      groups.set(date, []);
    }
    groups.get(date).push(message);
    return groups;
  }, new Map());
};

export const isEmptyObjectOrArray = (value) => {
  if (value === emptyObject) {
    return true;
  }

  if (value === emptyArray) {
    return true;
  }

  // If it's neither an object nor an array (and not null or undefined), return false
  return false;
};

export const generateTimeOptions = (startTime, endTime) => {
  const times = [];

  // Convert startTime and endTime strings to Date objects
  const currentTime = new Date();
  let [startHours, startMinutes] = startTime.split(":").map(Number);
  let [endHours, endMinutes] = endTime.split(":").map(Number);

  let startDateTime = new Date(
    currentTime.setHours(startHours, startMinutes, 0, 0)
  );
  const endDateTime = new Date(
    currentTime.setHours(endHours, endMinutes, 0, 0)
  );

  while (startDateTime <= endDateTime) {
    const hours = String(startDateTime.getHours()).padStart(2, "0");
    const minutes = String(startDateTime.getMinutes()).padStart(2, "0");
    times.push(`${hours}:${minutes}`);
    startDateTime = new Date(startDateTime.getTime() + 30 * 60000); // Add 30 minutes
  }

  return times;
};

export const getFormattedTimeFromDate = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};
