import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  IconButton,
  Box,
  VStack,
  useToast,
  HStack,
  Tooltip,
  useColorModeValue,
  Heading,
  Text,
} from "@chakra-ui/react";
import {
  AddIcon,
  DeleteIcon,
  CheckCircleIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { hitBeApi } from "../../apis/api";
import { serverBaseUrl, uploadFileUrl } from "../../constants/constants";
import { getCookieToken } from "../../utils/utils";
import Navbar from "./Navbar";

const VerifyUser = () => {
  const [documents, setDocuments] = useState([]);
  const toast = useToast();
  const [isFileBeingUploaded, setIsFileBeingUploaded] = useState(false);

  // Fetch verify documents when the component mounts
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await hitBeApi("user/documents/verify", "GET");
        setDocuments(response.data);
      } catch (error) {
        console.error("Error fetching documents:", error);
        toast({
          title: "Error fetching documents",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle file upload
  const handleFileUpload = async (event) => {
    if (isFileBeingUploaded) {
      toast({
        title: "Another file is in progress, please wait.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const token = getCookieToken();
    setIsFileBeingUploaded(true);

    try {
      const uploadResponse = await axios.post(
        `${serverBaseUrl}/${uploadFileUrl}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );

      const fileUrl = uploadResponse?.data?.data?.fileUrl;
      const fileName = uploadResponse?.data?.data?.fileName;

      // Post the uploaded file URL to the verify API
      await hitBeApi("user/documents/verify", "POST", { fileUrl, fileName });
      toast({
        title: "File uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Refresh the document list
      const refreshResponse = await hitBeApi("user/documents/verify", "GET");
      setDocuments(refreshResponse.data);
      setIsFileBeingUploaded(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsFileBeingUploaded(false);
      toast({
        title: "Error uploading file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle document deletion
  const handleDeleteDocument = async (docId) => {
    try {
      await hitBeApi(`user/documents/verify/${docId}`, "DELETE");
      toast({
        title: "Document deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Refresh the document list
      const refreshResponse = await hitBeApi("user/documents/verify", "GET");
      setDocuments(refreshResponse.data);
    } catch (error) {
      console.error("Error deleting document:", error);
      toast({
        title: "Error deleting document",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      margin="5%"
      justifyContent={"space-between"}
    >
      <Navbar />
      <VStack spacing={4} align="start" mt="2%" mr={{ base: "0", lg: "10%" }}>
        <Box>
          <Box textAlign="center" mt={8} mb={12}>
            <Heading
              as="h1"
              size={{ base: "medium", lg: "xl" }}
              mb={4}
              color={useColorModeValue("teal.600", "teal.300")}
            >
              Upload Your Document for profile Verification
            </Heading>
            <Text
              fontSize="lg"
              color={useColorModeValue("gray.700", "gray.400")}
            >
              Documents like university ID card or exam result certificate, or
              any other relevant source of proof.
            </Text>
          </Box>
          <IconButton
            icon={<AddIcon />}
            onClick={() => document.getElementById("fileInput").click()}
            aria-label="Add Document"
            disabled={isFileBeingUploaded}
            mr="5px"
          />
          Add New
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          {isFileBeingUploaded && (
            <p>please wait, file upload is in progress</p>
          )}
        </Box>
        <VStack spacing={2} align="start" mt="5%">
          {documents.map((doc, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="lg">
              <HStack spacing={4}>
                <a href={doc.fileUrl} target="_blank" rel="noopener noreferrer">
                  {doc.fileName || `Document ${index + 1}`}
                </a>
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Delete Document"
                  onClick={() => handleDeleteDocument(doc._id)}
                />
                {doc.isVerified ? (
                  <Tooltip label="This file is verified" aria-label="Verified">
                    <CheckCircleIcon color="green.500" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    label="Verification in progress"
                    aria-label="In Progress"
                  >
                    <WarningIcon
                      color="red.500"
                      cursor="pointer"
                      onClick={() =>
                        toast({
                          title: "File verification is in progress.",
                          status: "info",
                          duration: 5000,
                          isClosable: true,
                        })
                      }
                    />
                  </Tooltip>
                )}
              </HStack>
            </Box>
          ))}
        </VStack>
      </VStack>
    </Box>
  );
};

export default VerifyUser;
