import React, { useState } from "react";
import { Box, Button, Input, Select, useToast } from "@chakra-ui/react";
import Navbar from "./Navbar";
import DataTable from "../utils/MyTable";
import { hitBeApi } from "../../apis/api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Availability = () => {
  const [availabilityAddedCount, setAvailabilityAddedCount] = useState(0);
  const availability = useSelector((state) => state.availability);
  const [dateAvailabilityForm, setDateAvailabilityForm] = useState({
    date: "",
    startTime: "",
    endTime: "",
  });
  const [weekAvailabilityForm, setWeekAvailabilityForm] = useState({
    dayOfWeek: "",
    startTime: "",
    endTime: "",
  });
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const saveAvailability = async (type) => {
    let date, startTime, endTime, dayOfWeek;

    if (type === "date") {
      ({ date, startTime, endTime } = dateAvailabilityForm);
    } else {
      ({ dayOfWeek, startTime, endTime } = weekAvailabilityForm);
    }

    // Validate fields
    const isDayFieldProvided = date || dayOfWeek;
    if (!isDayFieldProvided || !startTime || !endTime) {
      toast({
        title: "Error",
        description: "Please provide all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Convert startTime and endTime to Date objects for comparison
    const startTimeDate = new Date(`1970-01-01T${startTime}:00`);
    const endTimeDate = new Date(`1970-01-01T${endTime}:00`);

    // Check if endTime is less than startTime
    if (endTimeDate <= startTimeDate) {
      toast({
        title: "Error",
        description: "End time cannot be earlier than or equal to start time.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (date && new Date(date) < new Date()) {
      toast({
        title: "Error",
        description: "Date cannot be in the past.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const finalAvailability = availability?.availabilitySlots
      ? [...availability.availabilitySlots]
      : [];

    if (type === "date" && date && date.length > 0) {
      finalAvailability.push({
        date,
        startTime,
        endTime,
      });
    }

    if (type === "week" && dayOfWeek && dayOfWeek.length > 0) {
      finalAvailability.push({
        dayOfWeek,
        startTime,
        endTime,
      });
    }

    try {
      if (availability?._id) {
        await hitBeApi(`availability/${availability._id}`, "PUT", {
          availabilitySlots: finalAvailability,
        });
      } else {
        await hitBeApi("availability", "POST", {
          availabilitySlots: finalAvailability,
        });
      }

      setAvailabilityAddedCount(availabilityAddedCount + 1);
      setDateAvailabilityForm({ date: "", startTime: "", endTime: "" });
      setWeekAvailabilityForm({ dayOfWeek: "", startTime: "", endTime: "" });
      toast({
        title: "Availability data saved successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error saving availability data.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleLogout = () => {
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    navigate("/");
    dispatch({ type: "Set_Mentor", value: {} });
  };

  const handleDateInputChange = (field, value) => {
    setDateAvailabilityForm({ ...dateAvailabilityForm, [field]: value });
  };

  const handleWeekInputChange = (field, value) => {
    setWeekAvailabilityForm({ ...weekAvailabilityForm, [field]: value });
  };

  const dateHeaders = ["date", "startTime", "endTime"];
  const weekHeaders = ["dayOfWeek", "startTime", "endTime"];

  const weeklyAvailabilitySlots = availability?.availabilitySlots?.filter(
    (slot) => slot.dayOfWeek
  );
  const datewiseAvailabilitySlots = availability?.availabilitySlots?.filter(
    (slot) => slot.date
  );

  const FormField = ({ label, type, value, onChange }) => (
    <Box padding="20px 0" width="100%">
      <h1>{label}</h1>
      <Input
        type={type}
        size="lg"
        marginTop="10px"
        required
        value={value}
        onChange={(e) => onChange(e.target.value)}
        _hover={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
        transition="box-shadow 0.3s"
      />
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      margin="5%"
      justifyContent={"space-between"}
    >
      <Navbar />
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        maxW={{ base: "100%", lg: "80%" }}
        mt={{ base: "10%", lg: "5%" }}
      >
        <Box
          display="flex"
          flexDirection={"row"}
          alignItems="center"
          justifyContent="space-between"
          fontSize={{ base: "xl", lg: "4xl" }}
          fontWeight="extrabold"
          mb={6}
        >
          <h1>Give Availability</h1>
          <Button onClick={handleLogout} bg="#FCB339">
            Logout
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          marginBottom="30px"
          borderRadius="20px"
          fontSize="xl"
          fontWeight="bold"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            borderWidth="1px"
            shadow="md"
            w={{ base: "100%", lg: "45%" }}
            marginRight={{ base: "0", lg: "2%" }}
            padding="5%"
            borderRadius="20px"
            transition="transform 0.3s, box-shadow 0.3s"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
            }}
          >
            <h1>Add availability for a date</h1>
            <FormField
              label="Select Date"
              type="date"
              value={dateAvailabilityForm.date}
              onChange={(value) => handleDateInputChange("date", value)}
            />
            <FormField
              label="Select Start Time"
              type="time"
              value={dateAvailabilityForm.startTime}
              onChange={(value) => handleDateInputChange("startTime", value)}
            />
            <FormField
              label="Select End Time"
              type="time"
              value={dateAvailabilityForm.endTime}
              onChange={(value) => handleDateInputChange("endTime", value)}
            />
            <Box padding="20px 0" width="100%">
              <Button
                bg="#FCB339"
                width="100%"
                fontSize="xl"
                onClick={() => saveAvailability("date")}
                _hover={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
                transition="box-shadow 0.3s"
              >
                Add Date Availability
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            borderWidth="1px"
            shadow="md"
            w={{ base: "100%", lg: "45%" }}
            mt={{ base: "50px", lg: "0px" }}
            padding="5%"
            borderRadius="20px"
            transition="transform 0.3s, box-shadow 0.3s"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
            }}
          >
            <h1>Add availability for a day of each week</h1>
            <Box padding="20px 0" width="100%">
              <h1>Weekday</h1>
              <Select
                size="lg"
                marginTop="10px"
                required
                value={weekAvailabilityForm.dayOfWeek}
                onChange={(e) =>
                  handleWeekInputChange("dayOfWeek", e.target.value)
                }
                _hover={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
                transition="box-shadow 0.3s"
              >
                <option value="">Select Day of the Week</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
              </Select>
            </Box>
            <FormField
              label="Select Start Time"
              type="time"
              value={weekAvailabilityForm.startTime}
              onChange={(value) => handleWeekInputChange("startTime", value)}
            />
            <FormField
              label="Select End Time"
              type="time"
              value={weekAvailabilityForm.endTime}
              onChange={(value) => handleWeekInputChange("endTime", value)}
            />
            <Box padding="20px 0" width="100%">
              <Button
                bg="#FCB339"
                width="100%"
                fontSize="xl"
                onClick={() => saveAvailability("week")}
                _hover={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
                transition="box-shadow 0.3s"
              >
                Add Week Availability
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          marginTop={"5%"}
        >
          <Box
            flex="1"
            mb={{ base: "20px", lg: "0" }}
            w={{ base: "100%", lg: "45%" }}
          >
            {datewiseAvailabilitySlots &&
              datewiseAvailabilitySlots.length > 0 && (
                <DataTable
                  title="Date-wise Availability"
                  headers={dateHeaders}
                  data={datewiseAvailabilitySlots}
                />
              )}
          </Box>
          <Box
            flex="1"
            ml={{ base: 0, lg: 10 }}
            mt={{ base: 10, lg: 0 }}
            w={{ base: "100%", lg: "45%" }}
          >
            {weeklyAvailabilitySlots && weeklyAvailabilitySlots.length > 0 && (
              <DataTable
                title="Weekly Availability"
                headers={weekHeaders}
                data={weeklyAvailabilitySlots}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Availability;
