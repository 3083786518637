import { Box, Button, Input, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Searches from "./Searches";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { hitBeApi } from "../../apis/api";

function Search() {
  const mentors = useSelector((state) => state.mentors);
  const mentees = useSelector((state) => state.mentees);
  const userMenteeProfile = useSelector((state) => state.mentee);
  const [menteesList, setMenteesList] = useState(mentees);
  const [mentorsList, setMentorsList] = useState(mentors);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    const filteredMentees = mentees?.filter(
      (mentee) => mentee.isPaymentDone && mentee?.profile?.user?.isVerified
    );
    setMenteesList(filteredMentees);
  }, [mentees]);

  useEffect(() => {
    const filteredMentors = mentors?.filter(
      (mentor) => mentor?.profile?.user?.isVerified
    );
    setMentorsList(filteredMentors);
  }, [mentors]);

  const handleSearch = async (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (userMenteeProfile && value && value.length > 0) {
      try {
        // Make the API call to search for mentors
        const response = await hitBeApi(`mentor/search?query=${value}`, "GET");

        // Check if there are mentors in the response
        if (response?.data?.length > 0) {
          // Map over the mentor list and fetch user profiles for each mentor
          const mentorProfiles = await Promise.all(
            response?.data?.map(async (mentor) => {
              // Fetch the user profile for each mentor using their userId
              const userRes = await hitBeApi(`user/${mentor.userId}`, "GET");
              // Assign the fetched profile to the mentor object
              mentor.profile = userRes?.data;
              return mentor;
            })
          );

          // Dispatch the updated mentors list to the state/store
          dispatch({ type: "Set_Mentors", value: mentorProfiles });
        } else {
          // Show a warning toast if no mentors are available
          toast({
            title: "Warning",
            description: "No more mentors available",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        // Handle any errors during the API call
        console.error("Error fetching mentors:", error);
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      const filteredMentees = mentees?.filter(
        (mentee) =>
          mentee.isPaymentDone &&
          mentee?.profile?.user?.isVerified &&
          mentee?.profile?.user?.name?.toLowerCase().includes(value)
      );
      setMenteesList(filteredMentees);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      margin="5%"
    >
      <Navbar />
      {
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={{ base: "10%", lg: "2%" }}
        >
          <Box textAlign="center" mb={6}>
            <Button
              colorScheme="teal"
              variant="outline"
              color="#2B68FF"
              border="2px solid #2B68FF"
              borderRadius="full"
              fontSize="lg"
              px={8}
              py={6}
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.08)",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
              }}
            >
              {userMenteeProfile &&
              userMenteeProfile.mentorId &&
              userMenteeProfile.mentorId.length !== 0
                ? `Congrats! Your mentor ${mentorsList[0].profile?.user?.name} is successfully assigned to you.`
                : `Find Your ${userMenteeProfile ? "Mentor" : "Mentee"}`}
            </Button>
          </Box>

          <Box
            textAlign="center"
            fontSize={{ base: "2xl", lg: "5xl" }}
            fontWeight="bold"
            mb={6}
          >
            <Text>Every Experience</Text>
            <Text>Every Domain Covered</Text>
          </Box>

          <Box
            mb={4}
            width={{ base: "100%", lg: "50%" }}
            textAlign={"center"}
            display={userMenteeProfile?.mentorId?.length ? "none" : "block"}
          >
            <Input
              placeholder="Search by name, college, city, or language"
              value={searchTerm}
              onChange={handleSearch}
              width={{ base: "100%", lg: "80%" }}
              variant="outline"
              borderColor="gray.300"
              _hover={{ borderColor: "blue.400" }}
              _focus={{
                borderColor: "blue.500",
                boxShadow: "0 0 8px rgba(43, 104, 255, 0.5)",
              }}
              transition="all 0.3s ease"
              paddingX={4} // Adjust padding for better text visibility
              paddingY={3} // Adjust padding for better text visibility
              fontSize="md" // Adjust font size for better visibility
              _placeholder={{ color: "gray.500", fontSize: "md" }} // Ensure placeholder text is styled appropriately
            />
          </Box>

          <Box width="100%">
            {userMenteeProfile ? (
              !mentorsList || mentorsList?.length === 0 ? (
                <Text
                  textAlign={"center"}
                  fontSize={{ base: "medium", lg: "xx-large" }}
                  marginTop={"30px"}
                >
                  No Mentors Present
                </Text>
              ) : (
                <Searches
                  mentors={mentorsList}
                  userMenteeProfile={userMenteeProfile}
                />
              )
            ) : !menteesList || menteesList?.length === 0 ? (
              <Text
                textAlign={"center"}
                fontSize={{ base: "medium", lg: "xx-large" }}
                marginTop={"30px"}
              >
                No Mentees Present
              </Text>
            ) : (
              <Searches
                mentees={menteesList}
                userMenteeProfile={userMenteeProfile}
              />
            )}
          </Box>
        </Box>
      }
    </Box>
  );
}

export default Search;
