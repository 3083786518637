import { Box, Button, Flex, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

function Intro() {
  const words = ["IIT JEE (Engineer's Dream)", "NEET (Doctor's Dream)"];
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(changeText, 2000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textIndex]);

  function changeText() {
    setTextIndex((textIndex + 1) % words.length);
  }

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      paddingTop={{ base: "1000px", lg: "70px" }}
      textAlign={"center"}
      padding={"20px"}
    >
      <Box
        fontSize={{ base: "2xl", lg: "4xl" }}
        fontWeight={"medium"}
        padding={"2%"}
      >
        <h1>1:1 Mentorship for</h1>
      </Box>
      <Box fontSize={{ base: "2xl", lg: "5xl" }} fontWeight={"bold"}>
        <h1
          className="hero-heading"
          style={{ transition: "opacity 0.5s ease" }}
        >
          {words[textIndex]}
        </h1>
      </Box>
      <Box
        padding={"2%"}
        marginTop={{ base: "10%", lg: "2%" }}
        fontSize={{ base: "lg", lg: "xl" }}
        fontWeight={"medium"}
      >
        <h3>
          Get started by booking a {!isMobile && <br></br>}
          <Button
            textColor={"#7FA2FF"}
            marginLeft={"10px"}
            marginRight={"10px"}
            mt={"5px"}
            bg="#FFFFFF"
            mb="5px"
            _hover={{
              backgroundColor: "#FFFFFF",
            }}
          >
            FREE TRIAL SESSION
          </Button>{" "}
          <br></br>
          with the mentor of your choice
        </h3>
      </Box>
      <Box
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent={"center"}
        alignItems={"center"}
        marginTop={"30px"}
        fontWeight={"bold"}
        gap={"10px"}
      >
        <Box>Find top mentors in:</Box>
        <Flex direction={"row"}>
          <Box>
            <Button
              textColor={"#7FA2FF"}
              bg="#FFFFFF"
              _hover={{
                backgroundColor: "#FFFFFF",
              }}
            >
              IIT-JEE
            </Button>{" "}
          </Box>
          <Box>
            <Button
              textColor={"#7FA2FF"}
              bg="#FFFFFF"
              _hover={{
                backgroundColor: "#FFFFFF",
              }}
            >
              NEET
            </Button>{" "}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default Intro;
