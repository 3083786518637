import { Box } from "@chakra-ui/react";
import React from "react";

function MentorshipFeature(props) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      padding={"20px 30px"}
      border={"0px"}
      shadow={"0px 0px 20px 0px rgba(0, 0, 0, 0.5)"}
      borderRadius={"20px"}
      maxWidth={{ base: "100%", lg: "30%" }}
      marginRight={{ base: "0%", lg: "3%" }}
      marginTop={{ base: "10%", lg: "2%" }}
    >
      <Box
        fontSize={{ base: "md", lg: "x-large" }}
        fontWeight={"bold"}
        textAlign={"left"}
      >
        <h1>{props.feature.heading}</h1>
      </Box>
      <Box fontSize={"sm"} textAlign={"left"} mt={"5%"}>
        <h1>{props.feature.content}</h1>
      </Box>
    </Box>
  );
}

export default MentorshipFeature;
