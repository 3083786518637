import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import MentorshipIntro from "./MentorshipIntro";
import ChoosePlan from "./ChoosePlan";

function MentorshipPlans() {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Box position="relative" overflow="hidden">
      <Box
        textAlign="center"
        opacity={openNavbar ? 0.1 : 1}
        transition="opacity 0.3s"
      >
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>

      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>

      <Box
        position="relative"
        top="110px"
        padding={"5% 7%"}
        paddingTop={{ base: "10%", lg: "7%" }}
        marginBottom={"5%"}
        opacity={openNavbar ? 0.1 : 1}
        transition="opacity 0.3s"
        onClick={() => setOpenNavbar(false)}
      >
        <MentorshipIntro />
        <ChoosePlan></ChoosePlan>
      </Box>

      <Box
        textAlign="center"
        opacity={openNavbar ? 0.1 : 1}
        transition="opacity 0.3s"
        onClick={() => setOpenNavbar(false)}
      >
        <Footer />
      </Box>
    </Box>
  );
}

export default MentorshipPlans;
