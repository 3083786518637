import React, { useState } from "react";
import { Avatar, Box, Button, Input, useToast } from "@chakra-ui/react";
import DynamicForm from "../utils/Update";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { hitBeApi } from "../../apis/api";
import axios from "axios";
import { serverBaseUrl, uploadFileUrl } from "../../constants/constants";
import { getCookieToken } from "../../utils/utils";

function MenteeProfile() {
  const userSaved = useSelector((state) => state.user);
  const addressSaved = useSelector((state) => state.address);
  const menteeSaved = useSelector((state) => state.mentee);
  const educationSaved = useSelector((state) => state.education);
  const examsQualifiedSaved = useSelector((state) => state.examsQualified);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageBeingUpload, setIsImageBeingUploaded] = useState(false);

  const handleLogoutSubmit = () => {
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    navigate("/");
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!selectedImage) {
      toast({
        title: "No file selected",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (isImageBeingUpload) {
      toast({
        title: "Wait, image is being uploaded",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedImage);
    const token = getCookieToken();

    try {
      setIsImageBeingUploaded(true);
      const response = await axios.post(
        `${serverBaseUrl}/${uploadFileUrl}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );

      setSelectedImage(null);
      setIsImageBeingUploaded(false);

      const imageUrl = response?.data?.data?.fileUrl;
      const updatedUser = { ...userSaved, imageUrl };
      handleDetailsUpdate({ user: updatedUser });

      toast({
        title: "File uploaded successfully.",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error uploading image.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDetailsUpdate = async (req) => {
    const { user, address, education, examsQualified, mentee } = req;
    const requestEndpoint = "user/" + userSaved._id;
    try {
      await hitBeApi(requestEndpoint, "PUT", {
        user: user,
        address: address,
        education: education,
        examsQualified: examsQualified,
        mentee: mentee,
      });
      dispatch({
        type: "Set_User",
        value: user || userSaved,
      });
      dispatch({
        type: "Set_Address",
        value: address || addressSaved,
      });
      dispatch({
        type: "Set_Exams",
        value: examsQualified || examsQualifiedSaved,
      });
      dispatch({
        type: "Set_Education",
        value: education || educationSaved,
      });
      dispatch({
        type: "Set_Mentee",
        value: mentee || menteeSaved,
      });
      toast({
        title: "Updated mentee details successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating mentee details.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={{ base: "column", lg: "row" }}
      margin={"5% 5%"}
    >
      <Navbar></Navbar>
      <Box display={"flex"} flexDirection={"column"} marginRight={"20px"}>
        <Box
          marginTop={{ base: "20px", lg: "0px" }}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
        >
          <Button
            onClick={handleLogoutSubmit}
            bg="#FCB339"
            _hover={{
              backgroundColor: "#FCB339",
              color: "white",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            }}
            transition="box-shadow 0.3s"
          >
            Logout
          </Button>
        </Box>
        <Box marginTop={"5%"} display={"flex"} flexDirection={"column"}>
          <Box
            display={"flex"}
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent={"flex-start"}
            alignContent={"center"}
            alignItems={"center"}
            margin={4}
          >
            <Box
              zIndex={"1000"}
              _hover={{
                transform: "scale(5.0)", // Increase the size on hover
                transition: "transform 1.4s ease-in-out", // Smooth transition effect
              }}
            >
              <Avatar
                name={userSaved?.name}
                src={userSaved?.imageUrl}
                height="80px"
                width="80px"
                borderRadius="full"
                mr={4}
              />
            </Box>
            <Input
              type="file"
              accept="image/*"
              border={"none"}
              onChange={handleImageChange}
              maxW={"300px"}
              mt={{ base: "20px", lg: "0px" }}
            />
            <Button
              colorScheme="teal"
              marginTop={{ base: "20px", lg: "0px" }}
              onClick={handleImageUpload}
              isDisabled={!selectedImage || isImageBeingUpload}
            >
              {isImageBeingUpload ? "Uploading .." : "Upload New Profile Image"}
            </Button>
          </Box>
          <DynamicForm
            object={userSaved || {}}
            objectName={"user"}
            handleUpdate={handleDetailsUpdate}
          ></DynamicForm>
          <DynamicForm
            object={menteeSaved || {}}
            objectName={"mentee"}
            handleUpdate={handleDetailsUpdate}
          ></DynamicForm>
          <DynamicForm
            object={addressSaved || {}}
            objectName={"address"}
            handleUpdate={handleDetailsUpdate}
          ></DynamicForm>
          <DynamicForm
            object={educationSaved || {}}
            objectName={"education"}
            handleUpdate={handleDetailsUpdate}
          ></DynamicForm>
          <DynamicForm
            object={examsQualifiedSaved || {}}
            objectName={"examsQualified"}
            handleUpdate={handleDetailsUpdate}
          ></DynamicForm>
        </Box>
      </Box>
    </Box>
  );
}

export default MenteeProfile;
