import { Box } from "@chakra-ui/react";
import React from "react";
import Review from "./Review";
import "./ReviewsLine.css"; // Ensure this path is correct

const ReviewsLine = (props) => {
  return (
    <Box width={"100%"} overflow={"hidden"}>
      <Box className="reviews-container">
        {props?.reviews.map((review, index) => {
          return (
            <Review review={review} key={index} className="review"></Review>
          );
        })}
      </Box>
    </Box>
  );
};

export default ReviewsLine;
