import { Box, Button } from "@chakra-ui/react";
import React from "react";

function Step(props) {
  return (
    <Box
      width={{ base: "100%", lg: "30%" }}
      padding={{ base: "10%", lg: "2%" }}
      border={"1px solid black"}
      borderRadius={"40px"}
      margin={{ base: "3%", lg: "1%" }}
      height={"25%"}
      marginLeft={"0"}
      _hover={{
        boxShadow: "lg",
        transform: "scale(1.05)",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Box>
        <Button
          colorScheme="teal"
          variant="outline"
          textColor={"#EC490B"}
          border={"1px solid #EC490B"}
          borderRadius={"full"}
          _hover={{
            bg: "#EC490B",
            color: "white",
            border: "1px solid #EC490B",
          }}
          _active={{
            bg: "#D1410A",
            transform: "scale(0.95)",
          }}
        >
          {props.stepNumber}
        </Button>
      </Box>
      <Box
        fontSize={{ base: "medium", lg: "2xl" }}
        fontWeight={"bold"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        paddingTop={"5%"}
      >
        <h1>{props.intro}</h1>
      </Box>
    </Box>
  );
}

export default Step;
