import React, { useState } from "react";
import { Box, Heading, Text, Divider } from "@chakra-ui/react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const PrivacyPolicy = () => {
  const [openNavbar, setOpenNavbar] = useState(false);
  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"5%"}
        paddingBottom={{ base: "0%", lg: "5%" }}
        paddingTop={{ base: "40%", lg: "10%" }}
        marginBottom={{ base: "0%", lg: "0%" }}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Heading as="h1" size="lg" mb={4}>
          Privacy Policy
        </Heading>
        <Text mb={4}>
          Your privacy is important to us. This Privacy Policy outlines the
          types of personal information that is received and collected by
          MenteeMentors and how it is used.
        </Text>
        <Divider mb={4} />
        <Text mb={4}>
          <strong>Information Collection and Use</strong>
        </Text>
        <Text mb={4}>
          MenteeMentors collects personal information when you register with us
          or when you use our services. This information may include your name,
          email address, contact details, and other relevant information needed
          for the provision of our services.
        </Text>
        <Text mb={4}>
          We may also collect information about your use of our website, such as
          the pages you visit, the links you click, and other actions you take
          while using our site.
        </Text>
        <Text mb={4}>
          <strong>Log Data</strong>
        </Text>
        <Text mb={4}>
          Like many websites, MenteeMentors collects information that your
          browser sends whenever you visit our site. This may include
          information such as your IP address, browser type, browser version,
          the pages of our site that you visit, the time and date of your visit,
          the time spent on those pages, and other statistics.
        </Text>
        <Text mb={4}>
          <strong>Cookies</strong>
        </Text>
        <Text mb={4}>
          MenteeMentors may use cookies to improve your experience on our
          website. Cookies are small files that are stored on your computer's
          hard drive and help us analyze web traffic or recognize your
          preferences when you visit our site. You can choose to disable cookies
          in your browser settings, but this may affect your ability to access
          certain features or services on our site.
        </Text>
        <Text mb={4}>
          <strong>Security</strong>
        </Text>
        <Text mb={4}>
          MenteeMentors takes reasonable precautions to protect your personal
          information. However, no method of transmission over the Internet, or
          method of electronic storage, is 100% secure. Therefore, while we
          strive to use commercially acceptable means to protect your personal
          information, we cannot guarantee its absolute security.
        </Text>
        <Text mb={4}>
          <strong>Changes to This Privacy Policy</strong>
        </Text>
        <Text mb={4}>
          MenteeMentors may update this Privacy Policy from time to time. We
          will notify you of any changes by posting the new Privacy Policy on
          this page. You are advised to review this Privacy Policy periodically
          for any changes. Changes to this Privacy Policy are effective when
          they are posted on this page.
        </Text>
        <Text mb={4}>
          <strong>Contact Us</strong>
        </Text>
        <Text mb={4}>
          If you have any questions about this Privacy Policy, please contact us
          via email at privacy@menteementors.com.
        </Text>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
