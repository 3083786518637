import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Plans from "../utils/Plans";
import { hitBeApi } from "../../apis/api";
import { useSelector } from "react-redux";

const countryCodes = [
  { code: "+1", name: "USA" },
  { code: "+91", name: "India" },
  { code: "+81", name: "Japan" },
  { code: "+65", name: "Singapore" },
  { code: "+49", name: "Germany" },
  { code: "+33", name: "France" },
  { code: "+39", name: "Italy" },
  { code: "+31", name: "Netherlands" },
  { code: "+41", name: "Switzerland" },
  { code: "+44", name: "UK" },
];

function ChoosePlan({
  isUserLoggedIn = false,
  setShouldShowPlan,
  mentorSelectedId,
}) {
  const [openNavbar, setOpenNavbar] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userProfile = useSelector((state) => state.user);
  const userMenteeProfile = useSelector((state) => state.mentee);
  const [isPaymentStarted, setIsPaymentStarted] = useState(false);

  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: "",
    studentName: "",
    exam: "",
    countryCode: "+91",
  });
  const [selectedPlan, setSelectedPlan] = useState(null);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    if (isUserLoggedIn && userMenteeProfile) {
      let phoneNumber = userProfile.phoneNumber;

      // Find the matching country code from the array
      const selectedCountryCode = countryCodes.find((code) =>
        phoneNumber.startsWith(code.code)
      );

      // If a country code is found, remove it from the phone number
      if (selectedCountryCode) {
        phoneNumber = phoneNumber.slice(selectedCountryCode.code.length);
      }

      setFormData({
        phoneNumber: phoneNumber,
        email: userProfile.email,
        studentName: userProfile.name,
        countryCode: "+91",
        exam: userMenteeProfile?.goals[0],
      });
    }

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  const refreshPage = () => {
    window.location.reload();
  };

  const handlePayment = async () => {
    if (
      !formData.phoneNumber ||
      !formData.email ||
      !formData.studentName ||
      !formData.exam ||
      !formData.countryCode
    ) {
      toast({
        title: "Error",
        description: "Please fill all required details.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const phoneNumberWithCountryCode =
      formData.countryCode + formData.phoneNumber.toString();

    if (isPaymentStarted) {
      return;
    }

    try {
      setIsPaymentStarted(true);
      const orderData = await hitBeApi("order", "POST", {
        amount: selectedPlan.planReducedPrice,
        currency: "INR",
        phoneNumber: phoneNumberWithCountryCode,
        email: formData.email,
        name: formData.studentName,
        plan: selectedPlan?.plan_intro || "",
        mentorId: mentorSelectedId,
      });

      const options = {
        key: "rzp_test_Rxf2RNcLe4gTlS", // Replace with your Razorpay key
        amount: orderData.data?.orderDetails?.amount,
        currency: orderData.data.orderDetails.currency,
        name: "MenteeMentors",
        description: selectedPlan?.plan_intro,
        order_id: orderData.data.razorpay.orderId,
        handler: async function (response) {
          try {
            await hitBeApi("payment", "POST", {
              contact: phoneNumberWithCountryCode,
              email: formData.email,
              paymentPlan: selectedPlan?.plan_intro,
              orderId: orderData.data.razorpay.orderId,
              paymentId: response.razorpay_payment_id,
              mentorId: mentorSelectedId,
            });

            toast({
              title: "Payment Successful",
              description: `${
                isUserLoggedIn
                  ? "Your payment has been processed successfully!"
                  : "Your payment has been processed successfully!"
              }`,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            onClose();
            setShouldShowPlan(false);
            setIsPaymentStarted(false);
            refreshPage();
          } catch (error) {
            console.error("Error processing payment:", error);

            toast({
              title: "Payment Error",
              description:
                error.message ||
                "There was an error processing your payment. Please try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setIsPaymentStarted(false);
          }
        },
        prefill: {
          name: formData.studentName,
          email: formData.email,
          contact: phoneNumberWithCountryCode,
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error processing order:", error);

      toast({
        title: "Order Error",
        description:
          error.message ||
          "There was an error processing your order. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsPaymentStarted(false);
    }
  };

  return (
    <Box position="relative" overflowY="scroll">
      <Box
        position="relative"
        top={{ base: "0px", lg: "110px" }}
        padding={{ base: "0% 0%", lg: "5% 2%" }}
        paddingTop={{ base: "10%", lg: "7%" }}
        opacity={openNavbar ? 0.1 : 1}
        transition="opacity 0.3s"
        onClick={() => setOpenNavbar(false)}
        overflowY="scroll"
      >
        <Plans
          isUserLoggedIn={isUserLoggedIn}
          onChoosePlan={(plan) => {
            setSelectedPlan(plan);
            onOpen();
          }}
          setShouldShowPlan={setShouldShowPlan}
        />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isUserLoggedIn && userMenteeProfile
              ? "Please Confirm"
              : "Please Enter"}{" "}
            Your Details
          </ModalHeader>
          {!(isUserLoggedIn && userMenteeProfile) && (
            <>
              <ModalHeader fontSize={"small"} mt={"0px"} pt="0px">
                Please make sure you are have completed signup as a mentee
              </ModalHeader>
              <ModalHeader fontSize={"small"} mt={"0px"} pt="0px">
                Use the same phone number/email as you used for signup.
              </ModalHeader>
            </>
          )}

          <ModalCloseButton />
          <ModalBody>
            <FormControl id="studentName" isRequired>
              <FormLabel>Student Name</FormLabel>
              <Input
                type="text"
                name="studentName"
                value={formData.studentName}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="countryCode" isRequired>
              <FormLabel mt="10px" mb="10px">
                Country Code
              </FormLabel>
              <Select
                name="countryCode"
                value={formData.countryCode}
                onChange={handleInputChange}
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="phoneNumber" isRequired>
              <FormLabel mt="10px" mb="10px">
                Phone Number
              </FormLabel>
              <Input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel mt="10px" mb="10px">
                Email
              </FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="exam" isRequired>
              <FormLabel mt="10px" mb="10px">
                Exam
              </FormLabel>
              <Input
                type="text"
                name="exam"
                value={formData.exam}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handlePayment}>
              Make Payment
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ChoosePlan;
