import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { AddIcon, StarIcon } from "@chakra-ui/icons"; // Import Chakra UI icons

function Question(props) {
  const [openQuestions, setOpenQuestions] = useState([]);
  const QuestionModalClick = (questionIndex) => {
    if (openQuestions.includes(questionIndex)) {
      const numberIndex = openQuestions.indexOf(questionIndex);
      const deletedQuestionList = openQuestions
        .slice(0, numberIndex)
        .concat(openQuestions.slice(numberIndex + 1, openQuestions.length));
      setOpenQuestions(deletedQuestionList);
    } else {
      setOpenQuestions((questions) => {
        return [...questions, questionIndex];
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={{ base: "90%", lg: "70%" }}
      alignItems="flex-start" // Centering the main box
      backgroundColor="#F2F2F7"
      borderRadius="20px"
      marginTop={{ base: "20px", lg: "50px" }}
      padding={{ base: "2px 5px", lg: "10px 20px" }}
      onClick={() => QuestionModalClick(props.index)}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding={{ base: "5px 20px", lg: "10px 20px" }}
        width="100%"
      >
        <Box fontSize={{ base: "sm", lg: "2xl" }} fontWeight="semibold">
          <h1>{props.question}</h1>
        </Box>
        <Box>
          <Box display={openQuestions.includes(props.index) ? "none" : "block"}>
            <AddIcon />{" "}
            {/* Replacing FontAwesome plus icon with Chakra UI AddIcon */}
          </Box>
          <Box display={openQuestions.includes(props.index) ? "block" : "none"}>
            <StarIcon />{" "}
            {/* Replacing FontAwesome star icon with Chakra UI StarIcon */}
          </Box>
        </Box>
      </Box>
      <Box
        display={openQuestions.includes(props.index) ? "block" : "none"}
        textAlign="left"
        padding={{ base: "5px 20px", lg: "10px 20px" }}
        width="100%"
      >
        <h1>{props.answer}</h1>
      </Box>
    </Box>
  );
}

export default Question;
