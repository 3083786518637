import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Input,
  Button,
  VStack,
  HStack,
  Avatar,
  Text,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import {
  AttachmentIcon,
  ArrowForwardIcon,
  CalendarIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import Navbar from "./Navbar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { hitBeApi } from "../../apis/api";
import DataTable from "../utils/MyTable";
import {
  formatDate2,
  getCookieToken,
  getFieldsOnly,
  groupMessagesByDate,
} from "../../utils/utils";
import MessageBox from "../utils/MessageBox";
import DatePicker from "react-datepicker";
import io from "socket.io-client";
import "react-datepicker/dist/react-datepicker.css";
import {
  SOCKET_SERVER_URL,
  serverBaseUrl,
  uploadFileUrl,
} from "../../constants/constants";

const ChatBox = () => {
  const [message, setMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [messages, setMessages] = useState([]);
  const [areSearchResults, setAreSearchResults] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(1);
  const [filteredConnections, setFilteredConnections] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const userMenteeProfile = useSelector((state) => state.mentee);
  const conversationSearchName = useSelector(
    (state) => state.conversationSearchName
  );
  const [isUploading, setIsUploading] = useState(false);

  const dispatch = useDispatch();
  const socketRef = useRef();
  const messageRefs = useRef({});
  const toast = useToast();

  const user = useSelector((state) => state.user);
  const mentees = useSelector((state) => state.mentees);
  const mentors = useSelector((state) => state.mentors);

  const conversations = useSelector((state) => state.conversations);
  const [selectedFile, setSelectedFile] = useState(null);

  const groupedMessages = groupMessagesByDate(messages);

  const fileInputRef = useRef();

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    socketRef.current = io(SOCKET_SERVER_URL, {
      transports: ["websocket"],
      reconnectionAttempts: 5,
    });

    socketRef.current.on("allMessages", (data) => {
      setMessages(data);
    });

    socketRef.current.on("joinConversationError", (error) => {
      toast({
        title: "Failed to join Conversation.",
        description: error.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });

    socketRef.current.on("markMessageReadError", (error) => {
      toast({
        title: "Failed to mark message as read.",
        description: error.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });

    socketRef.current.on("sendMessageError", (error) => {
      toast({
        title: "Failed to send message.",
        description: error.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });

    socketRef.current.on("deleteMessageError", (error) => {
      toast({
        title: "Failed to delete message.",
        description: error.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });

    // Cleanup on component unmount
    return () => {
      // Leave conversation or disconnect socket as needed
      socketRef.current.emit("leaveConversation", {});
      socketRef.current.disconnect();
    };
  }, [toast]);

  useEffect(() => {
    if (
      conversations &&
      conversations.length > 0 &&
      user._id &&
      user._id.length > 0
    ) {
      setMessages([]);
      const conversationId = conversations[selectedConversation - 1]?._id;
      if (conversationId && conversationId.length > 0) {
        socketRef.current.emit("joinConversation", {
          conversationId,
          userId: user._id,
        });
      }
    }
    if (conversationSearchName && conversationSearchName.length > 0) {
      handleSearchTargetRoleWithName(conversationSearchName);
      dispatch({
        type: "Set_Conversation_Search_Name",
        value: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation, conversations, user._id]);

  const handleConversationSelection = (conversationNumber) => {
    setSelectedConversation(conversationNumber);
  };

  const handleSetMessage = (e) => {
    setMessage(e.target.value);
  };

  const setCloseSearchResultsTable = (value) => {
    setAreSearchResults(!value);
  };

  const handleSearchTargetRoleWithName = (newSearchTerm) => {
    const allExistingConnectedUserIds = conversations?.map((conversation) => {
      return conversation?.otherUsers[0]?.user?._id;
    });

    var userData = [];
    if (userMenteeProfile) {
      const filteredMentorsList = mentors?.filter(
        (mentor) =>
          mentor?.user?.name
            ?.toLowerCase()
            ?.includes(newSearchTerm?.toLowerCase()) &&
          !allExistingConnectedUserIds?.includes(mentor?.user?._id)
      );
      const mentorsUserData = filteredMentorsList?.map((mentor) => {
        return mentor?.user;
      });
      userData = mentorsUserData;
    } else {
      const filteredMenteesList = mentees?.filter(
        (mentee) =>
          mentee?.user?.name
            ?.toLowerCase()
            ?.includes(newSearchTerm?.toLowerCase()) &&
          !allExistingConnectedUserIds?.includes(mentee?.user?._id)
      );

      const menteesUserData = filteredMenteesList?.map((mentee) => {
        return mentee?.user;
      });

      userData = menteesUserData;
    }

    setFilteredConnections(userData);
    if (newSearchTerm.length > searchTerm?.length && userData?.length > 0) {
      setAreSearchResults(true);
    } else {
      setAreSearchResults(false);
    }
    setSearchTerm(newSearchTerm);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = formatDate2(date);

    // Helper function to parse date strings in "dd MMMM yyyy" format to Date objects
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split(" ");
      const monthIndex = new Date(`${month} 1, ${year}`).getMonth();
      return new Date(year, monthIndex, parseInt(day));
    };

    // Get all the dates from groupedMessages (already sorted)
    const dates = Array.from(groupedMessages.keys());

    // Convert formattedDate to Date object
    const formattedDateObj = parseDate(formattedDate);

    // Find the index of the first date that is later than the formattedDate
    const nextDate = dates.find((d) => parseDate(d) >= formattedDateObj);
    if (nextDate) {
      const nextDayMessages = groupedMessages.get(nextDate);
      const nextDayLatestMessage = nextDayMessages[nextDayMessages.length - 1];
      messageRefs.current[nextDayLatestMessage._id]?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // Scroll to the latest date when the component mounts
  useEffect(() => {
    if (groupedMessages.length !== 0) {
      const dates = Array.from(groupedMessages.keys());
      const latestDate = dates[dates.length - 1]; // Last element in the sorted dates array

      if (latestDate) {
        const latestMessages = groupedMessages.get(latestDate);
        const latestMessage = latestMessages[latestMessages.length - 1];
        messageRefs.current[latestMessage._id]?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedMessages]);

  const handleFileSelection = (event) => {
    if (selectedFile) {
      toast({
        title: "error:",
        description: "a file is already selected, send it first",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    toast({
      title: "success",
      description: "file is selected",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    setMessage(event?.target?.files[0]?.name || "");
    setSelectedFile(event.target.files[0]);
  };

  const handleSendMessage = async () => {
    if (selectedFile) {
      handleSendFile();
      setSelectedFile(null);
      setMessage("");
    } else {
      if (message.trim() && conversations.length !== 0) {
        const msg = {
          conversationId: conversations[selectedConversation - 1]._id,
          senderId: user._id,
          content: message,
        };

        socketRef.current.emit("sendMessage", msg);
        setMessage("");
      }
    }
  };

  const handleDeleteMessage = async (messageId, userId) => {
    const msg = {
      conversationId: conversations[selectedConversation - 1]._id,
      messageId: messageId,
      doerId: userId,
    };

    socketRef.current.emit("deleteMessage", msg);
  };

  const handleSendFile = async () => {
    setIsUploading(true); // Set uploading state to true
    if (!selectedFile) {
      toast({
        title: "No file selected",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    const token = getCookieToken();

    try {
      const response = await axios.post(
        `${serverBaseUrl}/${uploadFileUrl}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );

      const fileCloudUrl = response?.data?.data?.fileUrl;
      const fileName = response?.data?.data?.fileName;

      const msg = {
        conversationId: conversations[selectedConversation - 1]._id,
        senderId: user._id,
        content: fileCloudUrl,
        contentMetaData: fileName,
      };

      socketRef.current.emit("sendMessage", msg);

      toast({
        title: "File uploaded successfully to cloud for sending message",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error uploading file to cloud for sending message.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false); // Set uploading state back to false
    }
  };

  const handleStartConversation = async (newConnectionIndex) => {
    if (filteredConnections.length !== 0) {
      let maxNewChatsLimit = userMenteeProfile?.maxConversationsCreationLimit;
      if (userMenteeProfile && !userMenteeProfile.mentorId) {
        maxNewChatsLimit = 3;
      }
      if (
        userMenteeProfile &&
        userMenteeProfile.totalConversationsCreatedCount >= maxNewChatsLimit
      ) {
        setFilteredConnections([]);
        setSearchTerm("");
        setAreSearchResults(false);
        toast({
          title: "Error.",
          description:
            "You have exceeded all free chat limits under your current payment plan",
          status: "error",
          duration: 7000,
          isClosable: true,
        });
        return;
      }
      setSearchTerm("");
      var userIdsList = [];
      userIdsList.push(user?._id);
      userIdsList.push(filteredConnections[newConnectionIndex]?._id);
      await hitBeApi("conversation", "POST", {
        userIds: userIdsList,
      })
        .then(() => {
          setAreSearchResults(false);
          hitBeApi("conversation", "GET")
            .then(async (conversations) => {
              dispatch({
                type: "Set_User_Conversations",
                value: conversations.data,
              });
              if (
                userMenteeProfile &&
                Object.keys(userMenteeProfile).length !== 0
              ) {
                userMenteeProfile.totalConversationsCreatedCount =
                  userMenteeProfile.totalConversationsCreatedCount + 1;
                const updatedMenteeRes = await hitBeApi(
                  `mentee/${userMenteeProfile?._id}`,
                  "PUT",
                  userMenteeProfile
                );
                dispatch({ type: "Set_Mentee", value: updatedMenteeRes?.data });
              }
              toast({
                title: "Conversation created.",
                description:
                  "A new conversation has been created successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            })
            .catch((error) => {
              toast({
                title: "Failed to fetch conversations.",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            });
        })
        .catch((error) => {
          toast({
            title: "Failed to create a new conversation.",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={{ base: "column", lg: "row" }}
      margin={"5%"}
    >
      <Box
        opacity={areSearchResults ? "0.1" : "1"}
        onClick={() => setAreSearchResults(false)}
        minWidth={"20%"}
      >
        <Navbar></Navbar>
      </Box>

      {
        <Box
          bg="gray.400"
          borderRadius={"md"}
          w="95%"
          mt={{ base: "10%", lg: "0%" }}
        >
          <Flex height="90vh" direction={{ base: "column", lg: "row" }}>
            <Box
              minW={{ base: "100%", lg: "30%" }}
              bg={"gray.600"}
              p={4}
              borderRadius={"md"}
            >
              <Input
                placeholder={
                  userMenteeProfile ? "Search Mentors" : "Search Mentees"
                }
                mb={4}
                value={searchTerm}
                onChange={(e) => handleSearchTargetRoleWithName(e.target.value)}
                isDisabled={userMenteeProfile && userMenteeProfile.mentorId}
              />
              <VStack
                spacing={4}
                align="stretch"
                opacity={areSearchResults ? "0.1" : "1"}
                onClick={() => setAreSearchResults(false)}
                overflowY={"scroll"}
                maxHeight={{ base: "15vh", lg: "77vh" }} // Adjust the height as needed
              >
                {conversations?.length === 0 || !conversations ? (
                  <Box textAlign="center" mt={4}>
                    No Conversations
                  </Box>
                ) : (
                  conversations?.map((conversation, index) => {
                    const otherUser = conversation?.otherUsers?.[0]?.user;
                    return (
                      <Box
                        key={conversation?._id}
                        p={2}
                        bg={
                          selectedConversation - 1 === index
                            ? "gray.400"
                            : "gray.100"
                        }
                        borderRadius={"md"}
                        onClick={() => handleConversationSelection(index + 1)}
                        cursor="pointer"
                        _hover={{ bg: "blue.500" }}
                      >
                        <HStack>
                          <Avatar
                            name={otherUser?.name}
                            src={otherUser?.imageUrl}
                            bg={
                              selectedConversation - 1 === index
                                ? "#FCB339"
                                : "#D740C6"
                            }
                            boxShadow="md" // Adding a shadow for better visibility
                          />
                          <Box>
                            <Text fontWeight="bold">{otherUser?.name}</Text>
                            <Text fontSize="sm">
                              {groupedMessages.length !== 0 &&
                                groupedMessages[-1]?.[-1]}
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    );
                  })
                )}
              </VStack>
            </Box>

            <Box flex="1" p={4}>
              <Flex direction="column" height="100%">
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <HStack
                    ml="10px"
                    spacing={3}
                    alignItems="flex-start"
                    wrap="wrap"
                  >
                    <VStack align="flex-start" spacing={1} w="100%">
                      {/* This section is for the Avatar and User's Name on the same line */}
                      <HStack w="100%" justifyContent="flex-start" spacing={3}>
                        <Avatar
                          name={
                            conversations?.[selectedConversation - 1]
                              ?.otherUsers?.[0]?.user?.name
                          }
                          src={
                            conversations?.[selectedConversation - 1]
                              ?.otherUsers?.[0]?.user?.imageUrl
                          }
                          bg="#FCB339"
                          boxShadow="lg"
                          size="lg"
                        />
                        <Text
                          fontWeight="bold"
                          fontSize="md"
                          isTruncated
                          maxW="70%" // Ensure the name doesn't overflow
                        >
                          {
                            conversations?.[selectedConversation - 1]
                              ?.otherUsers?.[0]?.user?.name
                          }
                        </Text>
                      </HStack>

                      {/* This section is for the text that should be on a new line */}
                      <VStack align="flex-start" spacing={0} maxW="100%">
                        <Text
                          fontSize="sm"
                          color="gray.600"
                          isTruncated
                          maxW="100%"
                        >
                          {!userMenteeProfile &&
                            (!conversations?.[selectedConversation - 1]
                              ?.otherUsers?.[0].mentee.mentorId ||
                              conversations?.[selectedConversation - 1]
                                ?.otherUsers?.[0].mentee.mentorId.length ===
                                0) &&
                            `Payment is not yet done by this mentee, please ask mentee for payment to schedule a call.`}
                        </Text>
                        <Text fontSize="sm" color="gray.600" maxW="100%">
                          {userMenteeProfile &&
                            (!userMenteeProfile.mentorId ||
                              userMenteeProfile.mentorId.length === 0) &&
                            `Please book a long-term mentorship with this mentor to schedule a call/meeting.`}
                        </Text>
                      </VStack>
                    </VStack>
                  </HStack>
                </Flex>

                <Box
                  flex="1"
                  overflowY="auto"
                  bg="gray.100"
                  p={{ base: 1, lg: 4 }}
                  borderRadius="md"
                  mb={4}
                  maxH={{ base: "48vh", lg: "80vh" }}
                  overscrollY={"scroll"}
                >
                  {groupedMessages.size === 0 ? (
                    <Box textAlign="center" mt={4}>
                      No messages
                    </Box>
                  ) : (
                    Array.from(groupedMessages)?.map(([date, messages]) => (
                      <Box key={date} mb={4}>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          my={{ base: "2px", lg: "0px" }}
                        >
                          <Text
                            textAlign="center"
                            fontSize={{ base: "small", lg: "x-large" }}
                          >
                            {date}
                          </Text>
                          <Text
                            textAlign="center"
                            ml="10px"
                            fontSize={"xx-small"}
                          >
                            jump to
                          </Text>
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            customInput={
                              <IconButton
                                aria-label="Choose date"
                                icon={<CalendarIcon />}
                              />
                            }
                          />
                        </Box>

                        <VStack spacing={{ base: 1, lg: 2 }}>
                          {messages?.map((msg, index) => (
                            <Box
                              display={"flex"}
                              key={index}
                              flexDirection={"row"}
                              justifyContent={
                                msg?.senderId === user._id
                                  ? "flex-end"
                                  : "flex-start"
                              }
                              alignItems={"center"}
                              width={"100%"}
                              ref={(el) => (messageRefs.current[msg._id] = el)}
                            >
                              <MessageBox
                                key={msg._id}
                                message={msg}
                                user={user}
                              />
                              {msg?.senderId === user._id && (
                                <IconButton
                                  aria-label="Delete message"
                                  icon={<DeleteIcon />}
                                  height="100%" // Make the icon button's height the same as the MessageBox
                                  variant="ghost"
                                  onClick={() =>
                                    handleDeleteMessage(msg?._id, user?._id)
                                  }
                                />
                              )}
                            </Box>
                          ))}
                        </VStack>
                      </Box>
                    ))
                  )}
                </Box>

                <Flex mt={4}>
                  <Input
                    placeholder={
                      isUploading
                        ? "Please wait, file is being sent..."
                        : "Type a message..."
                    }
                    value={message}
                    marginRight={"20px"}
                    onChange={handleSetMessage}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSendMessage();
                      }
                    }}
                  />
                  <HStack>
                    <Input
                      type="file"
                      display="none"
                      ref={fileInputRef}
                      onChange={handleFileSelection}
                    />
                    <IconButton
                      aria-label="Send file"
                      icon={<AttachmentIcon />}
                      onClick={handleIconClick}
                    />
                  </HStack>
                  <Button
                    ml={2}
                    onClick={handleSendMessage}
                    rightIcon={<ArrowForwardIcon />}
                    fontSize={{ base: "x-small", lg: "large" }}
                  >
                    Send
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
      }

      {areSearchResults && filteredConnections.length !== 0 && (
        <Box
          width={{ base: "100%", lg: "30%" }}
          position="absolute"
          opacity={areSearchResults ? "1" : "0"}
          top="10%"
          left={{ base: "0%", lg: "30%" }}
          zIndex={"1000"}
          bg="white"
          boxShadow="md"
          borderRadius="md"
          p={4}
        >
          <DataTable
            title={"Mentor Search Results"}
            showClose={true}
            closeTable={setCloseSearchResultsTable}
            data={filteredConnections}
            handleRowSelection={handleStartConversation}
            headers={getFieldsOnly(
              filteredConnections[0],
              "name",
              "email",
              "phoneNumber"
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChatBox;
