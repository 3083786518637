import { Box, Button, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function Navbar(props) {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  return (
    <Box
      position={"fixed"}
      top={"0px"}
      right={props.openNavbar ? "0px" : "-400px"}
      transitionProperty={"right"}
      transitionDuration={"2s"}
      transitionTimingFunction={"ease"}
      height={"100vh"}
      padding={"2% 1%"}
      backgroundColor={"#EBFEF5"}
      display={props.openNavbar ? "block" : "none"}
      zIndex={"500"}
      minWidth={{ base: "100%", lg: "300px" }}
      paddingTop={"30px"}
      paddingLeft={"40px"}
    >
      <Box
        display={"flex"}
        flex={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        paddingBottom={"5%"}
      >
        <Button
          onClick={() => props.setOpenNavbar(false)}
          fontSize={"5xl"}
          marginRight={"35px"}
          backgroundColor={"#EBFEF5"}
          _hover={{ bg: "#EBFEF5" }}
        >
          X
        </Button>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        paddingBottom={"10px"}
        paddingTop={"20px"}
      >
        {isMobile && (
          <Box padding="1%">
            <i className="fa fa-solid fa-user"></i>
            <Button
              marginLeft={"10px"}
              backgroundColor={"#EBFEF5"}
              _hover={{
                bg: "#EBFEF5",
                paddingLeft: "20px",
                transition: "0.5s",
              }}
            >
              <Link to="/mentee-registration">Free Chat with Mentor</Link>
            </Button>
          </Box>
        )}
        {isMobile && (
          <Box padding="1%">
            <i className="fa fa-solid fa-smile"></i>
            <Button
              marginLeft={"10px"}
              backgroundColor={"#EBFEF5"}
              _hover={{
                bg: "#EBFEF5",
                paddingLeft: "20px",
                transition: "0.5s",
              }}
            >
              <Link to="/become-mentor">Become a Mentor</Link>
            </Button>
          </Box>
        )}
        <Box padding="1%">
          <i className="fa fa-solid fa-list"></i>
          <Button
            marginLeft={"10px"}
            backgroundColor={"#EBFEF5"}
            _hover={{ bg: "#EBFEF5", paddingLeft: "20px", transition: "0.5s" }}
          >
            <Link to="/mentorship-plans">Mentorship Plans</Link>
          </Button>
        </Box>
        <Box padding="1%">
          <i className="fa fa-solid fa-book"></i>
          <Button
            marginLeft={"10px"}
            backgroundColor={"#EBFEF5"}
            _hover={{ bg: "#EBFEF5", paddingLeft: "20px", transition: "0.5s" }}
          >
            <Link to="/blogs">Blogs</Link>
          </Button>
        </Box>
        <Box padding="1%">
          <i className="fa fa-solid fa-user"></i>
          <Button
            marginLeft={"10px"}
            backgroundColor={"#EBFEF5"}
            _hover={{ bg: "#EBFEF5", paddingLeft: "20px", transition: "0.5s" }}
          >
            <Link to="/contact-us">Contact Us</Link>
          </Button>
        </Box>
        <Box padding="1%">
          <i className="fa fa-solid fa-question"></i>
          <Button
            marginLeft={"10px"}
            backgroundColor={"#EBFEF5"}
            _hover={{ bg: "#EBFEF5", paddingLeft: "20px", transition: "0.5s" }}
          >
            <Link to="/faqs">FAQ's</Link>
          </Button>
        </Box>
        <Box
          width={"60%"}
          marginLeft={"20%"}
          marginRight={"20%"}
          marginTop={"10%"}
          marginBottom={"10%"}
        >
          <hr></hr>
        </Box>
        <Box padding="1%">
          <i className="fa fa-solid fa-book"></i>
          <Button
            marginLeft={"10px"}
            backgroundColor={"#EBFEF5"}
            _hover={{ bg: "#EBFEF5", paddingLeft: "20px", transition: "0.5s" }}
          >
            <Link to="/terms">Terms and Conditions</Link>
          </Button>
        </Box>
        <Box padding="1%">
          <i className="fa fa-solid fa-laptop"></i>
          <Button
            marginLeft={"10px"}
            backgroundColor={"#EBFEF5"}
            _hover={{ bg: "#EBFEF5", paddingLeft: "20px", transition: "0.5s" }}
          >
            <Link to="/privacy-policy">Privacy Policy</Link>
          </Button>
        </Box>
        <Box padding="1%">
          <i className="fa fa-solid fa-rupee"></i>
          <Button
            marginLeft={"10px"}
            backgroundColor={"#EBFEF5"}
            _hover={{ bg: "#EBFEF5", paddingLeft: "20px", transition: "0.5s" }}
          >
            <Link to="/refund-policy">Refund Policy</Link>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Navbar;
