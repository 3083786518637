import React, { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Button,
  Input,
  Select,
} from "@chakra-ui/react";
import {
  convertTo12HourFormat,
  formatDate,
  getReadableFormatDate,
  isValidTimeFormat,
  makeReadable,
} from "../../utils/utils";

const DataTable = ({
  title,
  headers,
  data,
  showClose,
  closeTable,
  handleRowSelection,
  maxWidth = "200px",
}) => {
  const [searchColumn, setSearchColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleColumnChange = (e) => {
    setSearchColumn(e.target.value);
  };

  const filteredData = data.filter((row) => {
    if (!searchColumn || !searchValue) {
      return true;
    }
    const cellValue = row[searchColumn]?.toString().toLowerCase();
    return cellValue?.includes(searchValue.toLowerCase());
  });

  return (
    <Box
      p={8}
      borderWidth={1}
      borderRadius="2xl"
      overflow="hidden"
      border="0px"
      shadow="0px 0px 20px 0px rgba(0, 0, 0, 0.9)"
      _hover={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
      transition="box-shadow 0.3s"
    >
      <Heading as="h2" size={{ base: "sm", lg: "lg" }} mb={4}>
        {title}
      </Heading>
      {showClose && (
        <Button onClick={() => closeTable(true)} mb="30px">
          X
        </Button>
      )}

      <Box mb={4} display="flex" alignItems="center">
        <Select
          placeholder="Search Column"
          onChange={handleColumnChange}
          mr={2}
        >
          {headers?.map((header, index) => (
            <option key={index} value={header}>
              {makeReadable(header)}
            </option>
          ))}
        </Select>
        <Input
          placeholder="Search..."
          value={searchValue}
          onChange={handleSearchChange}
        />
      </Box>

      <TableContainer>
        <Table variant="striped" colorScheme="teal">
          <TableCaption placement="top">{title}</TableCaption>
          <Thead>
            <Tr>
              {headers?.map((header, index) => (
                <Th
                  key={index}
                  minWidth={maxWidth}
                  maxWidth={maxWidth}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {makeReadable(header)}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredData?.map((row, rowIndex) => (
              <Tr key={rowIndex} onClick={() => handleRowSelection(rowIndex)}>
                {headers?.map((header, cellIndex) => (
                  <Td
                    key={cellIndex}
                    maxWidth={maxWidth}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {header === "meetingLink" ? (
                      <a
                        href={row[header]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row[header]}
                      </a>
                    ) : header === "date" ? (
                      getReadableFormatDate(row[header])
                    ) : isValidTimeFormat(row[header]) ? (
                      convertTo12HourFormat(row[header])
                    ) : header === "scheduledTime" ? (
                      formatDate(row[header])
                    ) : (
                      row[header]
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DataTable;
