import {
  Box,
  Button,
  useToast,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUser,
  faChessBoard,
  faTable,
  faCalendar,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getFirstName, isEmptyObjectOrArray } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { hitBeApi } from "../../apis/api";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useSelector((state) => state.user);
  const meetings = useSelector((state) => state.bookings);
  const availability = useSelector((state) => state.availability);
  const mentees = useSelector((state) => state.mentees);
  const mentors = useSelector((state) => state.mentors);
  const conversations = useSelector((state) => state.conversations);

  const fetchData = async () => {
    try {
      const apiCalls = [];

      if (isEmptyObjectOrArray(user)) {
        apiCalls.push(hitBeApi("user", "GET"));
      }

      if (isEmptyObjectOrArray(conversations)) {
        apiCalls.push(hitBeApi("conversation", "GET"));
      }

      if (isEmptyObjectOrArray(meetings)) {
        apiCalls.push(hitBeApi("meeting", "GET"));
      }

      if (isEmptyObjectOrArray(availability)) {
        apiCalls.push(hitBeApi("availability", "GET"));
      }

      if (isEmptyObjectOrArray(mentees)) {
        apiCalls.push(hitBeApi("mentee", "GET"));
      }

      if (isEmptyObjectOrArray(mentors)) {
        apiCalls.push(hitBeApi("mentor?page=1", "GET"));
      }
      const numberOfApiCalls = apiCalls.length;

      const results = await Promise.all(apiCalls);

      if (isEmptyObjectOrArray(user)) {
        const userData = results.shift();
        dispatch({ type: "Set_User", value: userData?.data?.user });
        dispatch({
          type: "Set_Login_Phone_Number",
          value: userData?.data?.user.phoneNumber,
        });
        dispatch({ type: "Set_Mentor", value: userData?.data?.mentor });
        dispatch({ type: "Set_Mentee", value: userData?.data?.mentee });
        dispatch({ type: "Set_Address", value: userData?.data?.address });
        dispatch({ type: "Set_Education", value: userData?.data?.education });
        dispatch({ type: "Set_Exams", value: userData?.data?.exams });
      }

      if (isEmptyObjectOrArray(conversations)) {
        const conversationsData = results.shift();
        dispatch({
          type: "Set_User_Conversations",
          value: conversationsData?.data,
        });
      }

      if (isEmptyObjectOrArray(meetings)) {
        const meetingsData = results.shift();
        dispatch({ type: "Set_Bookings", value: meetingsData?.data });
      }

      if (isEmptyObjectOrArray(availability)) {
        const availabilityData = results.shift();
        dispatch({ type: "Set_Availability", value: availabilityData?.data });
      }

      if (isEmptyObjectOrArray(mentees)) {
        const menteesData = results.shift();
        const menteeProfiles = await Promise.all(
          menteesData?.data?.map(async (mentee) => {
            const userRes = await hitBeApi(`user/${mentee.userId}`, "GET");
            mentee.profile = userRes?.data;
            return mentee;
          })
        );
        dispatch({ type: "Set_Mentees", value: menteeProfiles });
      }

      if (isEmptyObjectOrArray(mentors)) {
        const mentorsData = results.shift();
        const mentorProfiles = await Promise.all(
          mentorsData?.data?.map(async (mentor) => {
            const userRes = await hitBeApi(`user/${mentor.userId}`, "GET");
            mentor.profile = userRes?.data;
            return mentor;
          })
        );
        dispatch({ type: "Set_Mentors", value: mentorProfiles });
      }

      if (numberOfApiCalls > 0) {
        toast({
          title: "Dashboard Data fetched successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error fetching dashboard data. (please login)",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      if (error.message.includes("Unauthorized")) {
        document.cookie =
          "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Box display={"flex"} flexDirection={"column"} minW={"20%"}>
      <Box
        padding={{ base: "0px 0px 10px 0px", lg: "0px 20px 10px 10px" }}
        fontWeight={"extrabold"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent="space-between"
      >
        <Box display={"inline"} fontSize={"2xl"}>
          <h1>
            <Link to="/">
              <Image
                height={"25px"}
                src="/logo.png"
                alt="logo"
                mr="5px"
                display={"inline"}
              ></Image>
              MenteeMentors
            </Link>
          </h1>
        </Box>
        {isMobile && (
          <Box
            display={"inline"}
            opacity={"1000"}
            zIndex={"5000"}
            marginLeft={{ base: "30px", lg: "0px" }}
          >
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<FontAwesomeIcon icon={faBars} />}
                variant="outline"
                aria-label="Options"
              />
              <MenuList>
                <MenuItem as={Link} to="/dashboard">
                  <FontAwesomeIcon icon={faChessBoard} />
                  <Box ml="2"> Home</Box>
                </MenuItem>
                <MenuItem as={Link} to="/dashboard/availability">
                  <FontAwesomeIcon icon={faTable} />
                  <Box ml="2">My Availability</Box>
                </MenuItem>
                <MenuItem as={Link} to="/dashboard/bookings">
                  <FontAwesomeIcon icon={faTable} />
                  <Box ml="2">My Meetings</Box>
                </MenuItem>
                <MenuItem as={Link} to="/dashboard/calendar">
                  <FontAwesomeIcon icon={faCalendar} />
                  <Box ml="2">My Calendar</Box>
                </MenuItem>
                <MenuItem as={Link} to="/dashboard/messages">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <Box ml="2">All Messages</Box>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
      </Box>

      {!isMobile && (
        <>
          <Box
            padding={"10px 30px 10px 30px"}
            backgroundColor={"#FCB339"}
            borderRadius={"full"}
            display={"flex"}
            flexDirection={"row"}
            _hover={{
              backgroundColor: "#FCB339",
              color: "white",
              boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
            }}
            alignItems={"center"}
            fontSize={"xl"}
            maxW={"70%"}
          >
            <Box textColor={"#FFFFFF"} fontSize={"2xl"}>
              <FontAwesomeIcon icon={faUser} />
            </Box>
            <Button
              marginLeft={"7px"}
              backgroundColor={"#FCB339"}
              _hover={{ backgroundColor: "#FCB339" }}
              fontSize={"2xl"}
            >
              {user ? getFirstName(user.name) : "Loading..."}
            </Button>
          </Box>
          <Box
            padding={{ base: "30px 10px 10px 10px", lg: "30px 20px 10px 10px" }}
            fontSize={"3xl"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <FontAwesomeIcon icon={faChessBoard} />
            <Button
              marginLeft={"7px"}
              backgroundColor={"#FFFFFF"}
              _hover={{ fontSize: "2xl", bg: "#FFFFFF" }}
              transition="font-size 0.3s"
            >
              <Link to="/dashboard"> Home</Link>
            </Button>
          </Box>
          <Link to="/dashboard/availability">
            <Box
              padding={"10px 20px 10px 10px"}
              fontSize={"3xl"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <FontAwesomeIcon icon={faTable} />
              <Button
                marginLeft={"7px"}
                backgroundColor={"#FFFFFF"}
                _hover={{ fontSize: "2xl", bg: "#FFFFFF" }}
                transition="font-size 0.3s"
              >
                My Availability
              </Button>
            </Box>
          </Link>
          <Link to="/dashboard/bookings">
            <Box
              padding={"10px 20px 10px 10px"}
              fontSize={"3xl"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <FontAwesomeIcon icon={faTable} />
              <Button
                marginLeft={"7px"}
                backgroundColor={"#FFFFFF"}
                _hover={{ fontSize: "2xl", bg: "#FFFFFF" }}
                transition="font-size 0.3s"
              >
                My Meetings
              </Button>
            </Box>
          </Link>
          <Link to="/dashboard/calendar">
            <Box
              padding={"10px 20px 10px 10px"}
              fontSize={"3xl"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <FontAwesomeIcon icon={faCalendar} />
              <Button
                marginLeft={"7px"}
                backgroundColor={"#FFFFFF"}
                _hover={{ fontSize: "2xl", bg: "#FFFFFF" }}
                transition="font-size 0.3s"
              >
                My Calendar
              </Button>
            </Box>
          </Link>
          <Link to="/dashboard/messages">
            <Box padding={"10px 20px 10px 10px"} fontSize={"3xl"}>
              <FontAwesomeIcon icon={faEnvelope} />
              <Button
                marginLeft={"7px"}
                backgroundColor={"#FFFFFF"}
                _hover={{ fontSize: "2xl", bg: "#FFFFFF" }}
                transition="font-size 0.3s"
              >
                All Messages
              </Button>
            </Box>
          </Link>
        </>
      )}
    </Box>
  );
}

export default Navbar;
