import { Box, Img } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

function Carousal(props) {
  const carousals = [
    {
      imgSrc:
        "https://media.istockphoto.com/id/667434682/photo/personal-development-career-growth-and-motivation.jpg?s=612x612&w=0&k=20&c=qXmSz-c16rc9FvR6CG-VuoXW8tLOA5SwRvBn1zXzp6Q=",
      heading: "Direct Mentorship",
      content: "Get help from recent NEET/JEE toppers through daily sessions.",
    },
    {
      imgSrc:
        "https://cdn.pixabay.com/photo/2023/05/02/15/52/support-7965543_1280.jpg",
      heading: "Motivation from Toppers",
      content:
        "Learn the success secrets and stay motivated with tips from toppers.",
    },
    {
      imgSrc:
        "https://images.pexels.com/photos/9034987/pexels-photo-9034987.jpeg?auto=compress&cs=tinysrgb&w=800",
      heading: "Continuous Progress Evaluation",
      content: "Track your progress with regular tests and detailed feedback.",
    },
  ];

  const [carousalIndex, setCarousalIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(changeText, 5000); // Change text every 5 seconds

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carousalIndex]);

  function changeText() {
    setCarousalIndex((carousalIndex + 1) % carousals.length);
  }

  return (
    <Box
      width={{ base: "100%", lg: "100%" }}
      padding={"30px"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box>
          <Img
            src={carousals[carousalIndex].imgSrc}
            borderRadius={"30px"}
            alt="carousel image"
            width={{ base: "100%", lg: "100%" }}
            _hover={{
              transform: "rotateY(10deg) rotateX(10deg)",
              transition: "all 0.3s ease-in-out",
            }}
          ></Img>
        </Box>
        <Box
          fontSize={{ base: "medium", lg: "2xl" }}
          fontWeight={"medium"}
          textAlign={"center"}
          marginTop={"30px"}
          marginBottom={"20px"}
        >
          <Box
            fontSize={{ base: "xl", lg: "3xl" }}
            fontWeight={"extrabold"}
            marginBottom={"10px"}
          >
            <h1>{carousals[carousalIndex].heading}</h1>
          </Box>
          <h1>{carousals[carousalIndex].content}</h1>
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        {carousals.map((_, index) => (
          <Box
            key={index}
            width={carousalIndex === index ? "50px" : "20px"}
            height={"20px"}
            margin={"5px"}
            borderRadius={"full"}
            backgroundColor={carousalIndex === index ? "#1C1C1C" : "#8D8B8B"}
            cursor="pointer"
            _hover={{
              backgroundColor: "#333333",
              transition: "all 0.3s ease-in-out",
            }}
            onClick={() => setCarousalIndex(index)}
          ></Box>
        ))}
      </Box>
    </Box>
  );
}

export default Carousal;
