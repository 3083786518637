import { Box, Img } from "@chakra-ui/react";
import React from "react";

function Review(props) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignContent={"flex-start"}
      minWidth={{ base: "70%", lg: "30%" }}
      padding={{ base: "10%", lg: "3%" }}
      paddingBottom={"0%"}
      backgroundColor={"#F2F2F7"}
      borderRadius={"40px"}
      margin={"1%"}
      mx={{ base: "3%", lg: "1%" }}
      shadow={
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
      }
    >
      <Box fontSize={{ base: "xl", lg: "4xl" }}>
        <i className="fa fa-solid fa-quote-left"></i>
      </Box>
      <Box marginTop={"2%"} marginBottom={"10%"}>
        {props?.review?.review}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Box maxWidth={"50%"}>
          <Img src={props?.review?.profile} borderRadius={"50%"}></Img>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          marginLeft={"5%"}
        >
          <Box
            fontSize={{ base: "small", lg: "large" }}
            fontWeight={"semibold"}
          >
            {props?.review?.name}
          </Box>
          <Box fontSize={{ base: "small", lg: "large" }}>
            {props?.review?.profession}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Review;
