import { appState } from "../state/state";

export const appReducer = (state = appState, action) => {
  switch (action.type) {
    case "Logout": {
      return {
        ...appState,
      };
    }
    case "Set_User": {
      return {
        ...state,
        user: action.value,
      };
    }
    case "Set_Mentor": {
      return {
        ...state,
        mentor: action.value,
      };
    }
    case "Set_Mentors": {
      return {
        ...state,
        mentors: action.value,
      };
    }
    case "Set_Mentees": {
      return {
        ...state,
        mentees: action.value,
      };
    }
    case "Set_Mentee": {
      return {
        ...state,
        mentee: action.value,
      };
    }
    case "Set_Exams": {
      return {
        ...state,
        examsQualified: action.value,
      };
    }
    case "Set_Education": {
      return {
        ...state,
        education: action.value,
      };
    }
    case "Set_Availability": {
      return {
        ...state,
        availability: action.value,
      };
    }
    case "Set_Address": {
      return {
        ...state,
        address: action.value,
      };
    }
    case "Set_Bookings": {
      return {
        ...state,
        bookings: action.value,
      };
    }
    case "Set_User_Conversations": {
      return {
        ...state,
        conversations: action.value,
      };
    }
    case "Set_Login_Phone_Number": {
      return {
        ...state,
        loginPhoneNumber: action.value,
      };
    }
    case "Set_Conversation_Search_Name": {
      return {
        ...state,
        conversationSearchName: action.value,
      };
    }
    case "Set_Login_Email": {
      return {
        ...state,
        loginEmail: action.value,
      };
    }
    default:
      return state;
  }
};
